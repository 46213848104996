import React, { useState } from "react";
import { Input, Button, Col, Row, notification, Form } from "antd";
import { Auth } from "aws-amplify";
import dog from "../../assets/images/dog.png";
import Loading from "../common/Loading";
import { getDetails } from "../../api/Apis";
import AppConfig from "../../config/AppConfig";
import "./Login.css";
function Login() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    let lowerEmail = values.EmailAddress.toLowerCase();
    try {
      setLoading(true);
      const user = await Auth.signIn(lowerEmail, values.Password);

      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      getLoginDetails(userDetails);
    } catch (err) {
      console.log("Login Error:", err);
      if (err.code === "UserNotConfirmedException") {
        notification["error"]({
          message: "Registered user not confirmed.!",
        });
        setLoading(false);
      } else if (err.code === "PasswordResetRequiredException") {
        notification["error"]({
          message: "Something went wrong.!",
        });
        setLoading(false);
      } else if (err.code === "NotAuthorizedException") {
        setLoading(false);
        notification["error"]({
          message: "Wrong email or password. Please try again.",
        });
      } else if (err.code === "UserNotFoundException") {
        setLoading(false);
        notification["error"]({
          message: "User not found.!",
        });
      } else {
        notification["error"]({
          message: "Something went wrong.!",
        });
        setLoading(false);
      }
    }
  };

  const getLoginDetails = (userDetails) => {
    setLoading(true);
    getDetails(AppConfig["entityNames"]["users"], userDetails.userid)
      .then((result) => {
        if (result.success) {
          // setLoading(false);
          let data = result.data;
          localStorage.setItem("userLogin", true);
          localStorage.setItem("userDetails", JSON.stringify(data));

          setLoading(false);
          if (data.userProfile === "A") {
            window.location.replace("/dashboard");
          } else {
            notification["error"]({
              message: "User not found.!",
            });
          }
        }
      })
      .catch((error) => {
        console.log("User details fetch : ", error);
        // setLoading(false);
        notification["error"]({
          message: "Something went wrong.!",
        });
      });
  };

  const onFinishFailed = () => {
    notification["error"]({
      message: "Submit failed!",
    });
  };

  return (
    <Col span={24} className="background-login">
      <Row>
        <Col span={12} className="login-page">
          <div className="login-card show-on-moblie">
            <img
              src={dog}
              style={{
                height: "70px",
                width: "70px",
                marginLeft: "40%",
                marginTop: "140px",
              }}
              alt="Dog Logo"
            />
            <h3
              style={{ textAlign: "center", margin: "20px", fontSize: "25px" }}
            >
              LOGIN
            </h3>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="login-form"
              style={{ marginTop: "20px" }}
            >
              <Form.Item
                name="EmailAddress"
                label="Email Address"
                className="login-psrd"
                rules={[
                  {
                    required: true,
                    message: "Please input Email",
                  },
                ]}
              >
                <Input
                  placeholder="Email Address"
                  required
                  style={{ height: "50px" }}
                  type="email"
                />
              </Form.Item>
              <Form.Item
                name="Password"
                label="Password"
                className="login-psrd"
                rules={[
                  {
                    required: true,
                    message: "Please input Password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  style={{ height: "50px" }}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                style={{
                  background: "#E28141",
                  color: "white",
                  height: "50px",
                  width: "100%",
                }}
                loading={loading}
              >
                Login
              </Button>
            </Form>
            <p style={{ textAlign: "center" }}>Version 2.11.22.4</p>
          </div>
        </Col>
        <Col span={12}>
          <div className="background-img show-on-desktop"></div>
        </Col>
      </Row>

      <Loading enableLoading={loading} />
    </Col>
  );
}

export default Login;
