import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Form,
  notification,
  Modal,
  Collapse,
  Popconfirm,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { add, list, deleteRecord } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../common/Loading";
import "./Settings.css";
const { Option } = Select;
const { Panel } = Collapse;
export default function PetInfo() {
  const [open, setOpen] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [petBreed, setPetBreed] = useState("");
  useEffect(() => {
    getMasterData();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    let data = {
      entityData: values,
    };

    await add(appConfig["entityNames"]["petInfo"], data)
      .then((result) => {
        if (result.success) {
          document.getElementById("basic").reset();
          getMasterData();
          setLoading(false);
          setOpen(false);
          notification["success"]({
            message: "Record created successfully!",
          });
        } else {
          console.log("Error : ", result.error);
          setLoading(false);
          notification["error"]({
            message: "Something went wrong.!",
          });
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        setLoading(false);
        notification["error"]({
          message: "Something went wrong.!",
        });
      });
  };

  const getMasterData = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["petInfo"]).then((result) => {
        if (result.success) {
          setMasterData(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["petInfo"], id).then(
      (deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          getMasterData();
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    );
  };

  return (
    <div>
      <Col
        xxl={{ span: 10, offset: 7 }}
        xl={{ span: 16, offset: 4 }}
        lg={{ span: 16, offset: 4 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Col span={24}>
          <Row justify={"end"}>
            <Col span={21}>
              <span style={{ fontSize: "20px" }}>Pet Info</span>
            </Col>
            <Col span={3}>
              <Button
                onClick={() => setOpen(true)}
                style={{
                  backgroundColor: "#E28141",
                  color: "#fff",
                  height: "37px",
                }}
                icon={<PlusOutlined />}
              >
                <span> Add</span>
              </Button>
            </Col>
          </Row>
          <Collapse style={{ marginTop: "15px" }}>
            <Panel
              header={
                <span style={{ textTransform: "capitalize" }}>Dog Breeds</span>
              }
            >
              {masterData.map((t) => (
                <div>
                  <Col span={24}>
                    <Row>
                      <Col span={22}>
                        <span> {t?.title} </span>
                      </Col>
                      <Col span={2}>
                        <Popconfirm
                          title="Delete the Pet Breed"
                          description="Are you sure to delete this Pet Breed..?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => deleteItem(t.id)}
                        >
                          <Button style={{ border: "0px" }}>
                            <DeleteOutlined style={{ color: "red" }} />
                          </Button>
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                </div>
              ))}
            </Panel>
          </Collapse>
        </Col>
      </Col>
      <Modal
        title="Add Pet Breed"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
      >
        <Form name="basic" autoComplete="on" onFinish={onFinish}>
          {/* <Col span={24} style={{ marginTop: "20px" }}> */}
            {/* <span>Select PetType</span>
            <Form.Item
              name="dataType"
              rules={[
                {
                  required: true,
                  message: "Please select type!",
                },
              ]}
            >
              <Select
                onSelect={(value) => setPetBreed(value)}
                placeholder="Select type"
                className="customer"
              >
                <Option value="petType">Pet Type</Option>
                <Option value="petBreed">Pet Breed</Option>
              </Select>
            </Form.Item> */}
          {/* </Col> */}
          {petBreed === "petBreed" && (
            <>
              <Col span={24}>
                <span>Select Pet</span>
                <Form.Item name="petId">
                  <Select placeholder="Select petType">
                    {masterData
                      .filter((item) => item.dataType === "petType")
                      .map((item) => (
                        <Option
                          style={{ textTransform: "capitalize" }}
                          value={item.id}
                          key={item.id}
                        >
                          {item?.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={24}>
            <span>Pet Breed</span>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter Pet Breed!",
                },
              ]}
            >
              <Input placeholder="Please Enter Pet Breed" style={{ height: "40px" }} />
            </Form.Item>
          </Col>
          <Row justify={"end"}>
            <Col>
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#E28141",
                    color: "#fff",
                    height: "38px",
                    width: "70px",
                  }}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Loading enableLoading={loading} />
    </div>
  );
}
