import React, { useState } from "react";
import { Layout, Menu } from "antd";
import {
  SettingOutlined,
  PieChartOutlined,
  QqOutlined,
  UserOutlined,
  DollarOutlined,
  ScheduleOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { Link, BrowserRouter, Routes, Route } from "react-router-dom";
import AppRoutes from "../../config/AppRoutes";
import HeaderComponent from "../common/HeaderComponent";
import "./Application.css";
import Loading from "../common/Loading";
import pets2 from "../../assets/images/pets2.png";
const { Sider, Content } = Layout;

const Application = (props) => {
  const [openKeys, setOpenKeys] = useState("");
  const [collapsed, setcollapsed] = useState(true);
  const rootKeys = ["sub0", "sub1", "sub2", "sub3", "sub4", "sub5", "sub6"];
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const collapse = () => {
    setcollapsed(!collapsed);
    setShow(!show);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const signOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.replace("/login");
    setLoading(true);
  };

  return (
    <BrowserRouter>
      <Layout>
        <HeaderComponent onLogout={(e) => signOut(e)} />
        <Layout>
          <Sider
            collapsed={collapsed}
            onCollapse={() => collapse()}
            collapsible
            style={{
              minHeight: "100vh",
              background: "white",
              overflowX: "hidden",
              position: "fixed",
              marginLeft: "-7px",
            }}
            className="show-on-desktop"
          >
            <Menu
              inlineCollapsed={collapsed}
              onOpenChange={onOpenChange}
              openKeys={openKeys}
              defaultActiveFirst={["sub0"]}
              defaultOpenKeys={["sub0"]}
              style={{
                position: "relative",
                height: "100%",
                background: "white",
                marginTop: "80px",
              }}
            >
              <Menu.Item
                key="sub5"
                style={{ marginLeft: "3px" }}
                icon={
                  <PieChartOutlined
                    style={{ fontSize: "22px", marginLeft: "-3px" }}
                  />
                }
              >
                <Link to="/statistic">
                  <span></span>
                </Link>
                Statistics
              </Menu.Item>
              <Menu.Item
                key="sub0"
                style={{ marginLeft: "3px" }}
                icon={
                  <ScheduleOutlined
                    style={{ fontSize: "22px", marginLeft: "-3px" }}
                  />
                }
              >
                <Link to="/calenders">
                  <span></span>
                </Link>
                Calender
              </Menu.Item>
              <Menu.Item
                key="sub1"
                style={{ marginLeft: "3px" }}
                icon={
                  <FieldTimeOutlined
                    style={{ fontSize: "22px", marginLeft: "-3px" }}
                  />
                }
              >
                <Link to="/schedules">
                  <span></span>
                </Link>
                Schedules
              </Menu.Item>

              <Menu.Item
                key="sub3"
                style={{ marginLeft: "3px" }}
                icon={
                  <img
                    src={pets2}
                    style={{ height: "23px", width: "23px" }}
                    alt="pets2 Logo"
                  />
                }
              >
                <Link to="/pets">
                  <span> Pets</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="sub4"
                style={{ marginLeft: "3px" }}
                icon={
                  <DollarOutlined
                    style={{ fontSize: "22px", marginLeft: "-3px" }}
                  />
                }
              >
                <Link to="/payment">
                  <span></span>
                </Link>
                Payments
              </Menu.Item>
              <Menu.Item
                key="sub2"
                style={{ marginLeft: "3px" }}
                icon={
                  <UserOutlined
                    style={{ fontSize: "22px", marginLeft: "-3px" }}
                  />
                }
              >
                <Link to="/users">
                  <span></span>
                </Link>
                Users
              </Menu.Item>
              <Menu.Item
                key="sub6"
                style={{ marginLeft: "3px" }}
                icon={
                  <SettingOutlined
                    style={{ fontSize: "22px", marginLeft: "-3px" }}
                  />
                }
              >
                <Link to="/settings">
                  <span></span>
                </Link>
                Settings
              </Menu.Item>
            </Menu>
          </Sider>

          {collapsed === true ? (
            <Content
              style={{
                marginTop: "60px",
                // minHeight: "100vh",
                height: "91.8vh",
                marginLeft: "-15px",
                backgroundColor: "#f0f2f5",
              }}
              className="Content"
            >
              <div
                style={{
                  padding: "30px 20px 30px 30px",
                  overflowX: "hidden",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Routes>
                  {AppRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      key={route.key}
                    />
                  ))}
                </Routes>
              </div>
            </Content>
          ) : (
            <Content
              style={{
                paddingLeft: "210px",
                marginTop: "60px",
                // minHeight: "100vh",
                height: "91.8vh",
                marginLeft: "-15px",
                backgroundColor: "#f0f2f5",
              }}
            >
              <div
                style={{
                  padding: "30px 20px 30px 30px",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Routes>
                  {AppRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      key={route.key}
                    />
                  ))}
                </Routes>
              </div>
            </Content>
          )}

          <div className="mobile_view_footer show-on-mobile">
            <div
              style={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                to="/statistic"
                title="Statistic"
                style={{ display: "flex", alignItems: "center" }}
              >
                <PieChartOutlined
                  style={{
                    minWidth: "10vw",
                    fontSize: "28px",
                    color: "white",
                  }}
                  className="menuitem"
                />
              </Link>
            </div>

            <div
              style={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                to="/calenders"
                title="Calender"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ScheduleOutlined
                  style={{
                    minWidth: "10vw",
                    fontSize: "28px",
                    color: "white",
                  }}
                  className="menuitem"
                />
              </Link>
            </div>
            <div
              style={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                to="/schedules"
                title="Schedules"
                style={{ display: "flex", alignItems: "center" }}
              >
                <FieldTimeOutlined
                  style={{
                    minWidth: "10vw",
                    fontSize: "28px",
                    color: "white",
                  }}
                  className="menuitem"
                />
              </Link>
            </div>

            <div
              style={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                to="/pets"
                title="Pets"
                style={{ display: "flex", alignItems: "center" }}
              >
                <QqOutlined
                  style={{
                    minWidth: "10vw",
                    fontSize: "28px",
                    color: "white",
                  }}
                  className="menuitem"
                />
              </Link>
            </div>

            <div
              style={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                to="/payment"
                title="Payment"
                style={{ display: "flex", alignItems: "center" }}
              >
                <DollarOutlined
                  style={{
                    minWidth: "10vw",
                    fontSize: "28px",
                    color: "white",
                  }}
                  className="menuitem"
                />
              </Link>
            </div>

            <div
              style={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                to="/users"
                title="User"
                style={{ display: "flex", alignItems: "center" }}
              >
                <UserOutlined
                  style={{
                    minWidth: "10vw",
                    fontSize: "28px",
                    color: "white",
                  }}
                  className="menuitem"
                />
              </Link>
            </div>
            <div
              style={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                to="/settings"
                title="Settings"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SettingOutlined
                  style={{
                    minWidth: "15vw",
                    fontSize: "28px",
                    color: "white",
                  }}
                  className="menuitem"
                />
              </Link>
            </div>
          </div>
        </Layout>
      </Layout>
      <Loading enableLoading={loading} />
    </BrowserRouter>
  );
};
export default Application;
