import React, { useState, useEffect } from "react";
import { Table, Tag, notification, Modal, Col, Row, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import appConfig from "../../../config/AppConfig";
import { list } from "../../../api/Apis";
import Invoice from "../invoices/Invoice";
import Loading from "../../common/Loading";
import { PriceFormate } from "../../../Utilities/Index";

export default function Payment() {
  const [appointmentsInfo, setAppointmentsInfo] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [petsInfo, setPetsInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAppointments();
    getPetInfo();
  }, []);

  const getAppointments = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["appointments"]).then((result) => {
        if (result.success) {
          setAppointmentsInfo(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const getAppointmentRecords = () => {
    let records =
      search.length === 0
        ? appointmentsInfo
        : appointmentsInfo.filter(
            (e) =>
              e?.title?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
              e?.ownerName?.toLowerCase().indexOf(search?.toLowerCase()) > -1
          );

    records.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    return records;
  };

  const getPetInfo = async () => {
    try {
      list(appConfig["entityNames"]["petInfo"]).then((result) => {
        if (result.success) {
          let records = {};
          result.data?.map((petInfo) => {
            records[petInfo.id] = petInfo.title;
          });
          setPetsInfo(records);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const columns = [
    {
      title: "Payment Date & Time",
      dataIndex: "updatedAt",
      render: (updatedAt) => {
        return <span>{moment(updatedAt).format("MM/DD/yyyy hh:mm a")}</span>;
      },
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
    },
    {
      title: "Pet Name",
      dataIndex: "title",
    },
    {
      title: "Appointment Date & Time",
      dataIndex: "start",
      render: (start) => {
        return <span>{moment(start).format("MM/DD/yyyy hh:mm a")}</span>;
      },
    },
    {
      title: "Vaccine Name(s)",
      dataIndex: "vaccines",
      render: (vaccines) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {vaccines?.map((vaccine, index) => (
              <span key={index}>
                {vaccine.vaccineName}
                {index !== vaccines.length - 1 && ", "}
              </span>
            ))}
          </span>
        );
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "amount",
      render: (amount) => {
        return <span>{PriceFormate(amount)}</span>;
      },
    },
    {
      title: "Transaction Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <Tag
            style={{
              minWidth: "80px",
              textAlign: "center",
              textTransform: "capitalize",
            }}
            color={
              status === "failed"
                ? "red"
                : status === "pending"
                ? "gold"
                : "green"
            }
          >
            success
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify={"space-between"}>
        <Col>
          <span style={{ fontSize: "24px" }}>
            Payments ({getAppointmentRecords().length})
          </span>
        </Col>
        <Col>
          <Input
            placeholder="Search  Owner or Pet Name ..."
            style={{ height: "60px" }}
            addonBefore={<SearchOutlined />}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={getAppointmentRecords()}
        pagination={{ hideOnSinglePage: true }}
        style={{ overflow: "auto", cursor: "pointer" }}
        scroll={{ x: 300 }}
        onRow={(record) => ({
          onClick: () => {
            setPaymentDetails(record);
            setOpen(true);
          },
        })}
      />

      <Col span={24}>
        <Modal
          // title="INVOICE"
          open={open}
          onCancel={() => setOpen(false)}
          okButtonProps={{
            style: { display: "none" },
          }}
          cancelButtonProps={{
            style: { display: "none" },
          }}
          width={700}
        >
          <Invoice paymentDetails={paymentDetails} petsInfo={petsInfo} />
        </Modal>
      </Col>
      <Loading enableLoading={loading} />
    </div>
  );
}
