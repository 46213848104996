import React, { useEffect, useState } from "react";
import { Col, Row, Card, notification, Avatar, Image, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { getDetails } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Pets from "../pets/Pets";

export default function UserDetails(props) {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState([]);
  const [userPets, setUserPets] = useState([]);
  const [userAppointmentId, setUserAppointmentId] = useState("");

  useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      getUsers(id);
    } else {
      const { userId, petId, appointmentId } = props;
      setUserAppointmentId(appointmentId);
      getUsers(userId, petId);
    }
  }, [id, props]);

  const getUsers = async (userId, petId) => {
    try {
      const result = await getDetails(
        appConfig["entityNames"]["users"],
        userId
      );
      if (result.success) {
        setUserDetails(result.data);
        let userPetsResult = result.data?.petData;
        if (petId != null && petId !== undefined && petId !== "") {
          let pets = userPetsResult.filter((p) => p.id === petId);
          setUserPets(pets);
        } else {
          setUserPets(userPetsResult);
        }
      } else {
        notification.error({
          message: "Something went wrong!",
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong!",
      });
    }
  };
  const getImageUrl = (key) => {
    let url = `https://${appConfig["IMAGE_URL"]}.s3.amazonaws.com/public/${key}`;
    return url;
  };
  return (
    <div>
      {window.location.pathname === `/userDetails/${id}` ? (
        <>
          <Col span={24}>
            <Button
              style={{
                width: "80px",
                height: "35px",
                backgroundColor: "#E28141",
                color: "#fff",
              }}
              onClick={() => (window.location.pathname = "/users")}
            >
              <span>
                <ArrowLeftOutlined style={{ marginRight: "10px" }} />
              </span>{" "}
              Back
            </Button>
          </Col>
        </>
      ) : (
        <></>
      )}
      <Row gutter={[16, 16]} style={{ marginTop: "30px" }}>
        <Col
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          {userDetails.length === 0 ? (
            <>
              <h1>This User(s) Not Found</h1>
            </>
          ) : (
            <>
              <Card
                bodyStyle={{ padding: "20px 20px", height: "273px" }}
                hoverable
              >
                <Row justify={"center"}>
                  <Col>
                    <span style={{ fontSize: "60px", fontWeight: "bold" }}>
                      <Col>
                        {userDetails.image !== undefined &&
                        userDetails.image !== null &&
                        userDetails.image !== "" ? (
                          <Image
                            src={getImageUrl(userDetails.image)}
                            style={{
                              height: "110px",
                              width: "110px",
                              borderRadius: "70px",
                            }}
                          />
                        ) : (
                          <Avatar size={80}>
                            <span style={{ fontSize: "35px" }}>
                              {userDetails?.firstName?.charAt(0).toUpperCase()}
                            </span>
                          </Avatar>
                        )}
                      </Col>
                    </span>
                  </Col>
                </Row>

                <Row justify={"center"} style={{ marginTop: "15px" }}>
                  <Col>
                    <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                      {userDetails.firstName} {userDetails.lastName}
                    </span>
                  </Col>
                </Row>
                <Row justify={"center"}>
                  <Col>
                    <span style={{ fontSize: "20px" }}>
                      {userDetails.phone}
                    </span>
                  </Col>
                </Row>
                <Row justify={"center"}>
                  <Col>
                    <span
                      style={{
                        fontSize: "18px",
                        color: "gray",
                      }}
                    >
                      {userDetails.email}
                    </span>
                  </Col>
                </Row>
              </Card>
            </>
          )}
        </Col>
        <Col
          xxl={{ span: 18 }}
          xl={{ span: 18 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ marginTop: "-9px" }}
        >
          <>
            {userPets && userPets?.length < 1 ? (
              <>
                <Row justify="center">
                  <div className="centerItem">
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        marginTop: "50%",
                      }}
                    >
                      Pet(s) Not Found
                    </h1>
                  </div>
                </Row>
              </>
            ) : (
              <>
                {" "}
                {userPets && userPets?.length > 0 && (
                  <Pets
                    userPets={userPets}
                    userAppointmentId={userAppointmentId}
                    updateAppointment={props.updateAppointment}
                  />
                )}
              </>
            )}
          </>
        </Col>
      </Row>
    </div>
  );
}
