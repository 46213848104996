import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Modal,
  Input,
  Form,
  notification,
  Popconfirm,
} from "antd";
import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { add, list, deleteRecord, update } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../common/Loading";
import "./Settings.css";
const { TextArea } = Input;

export default function Location() {
  const formRef = React.createRef();
  const [editForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [currentLocation, setCurrentLocation] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleMap, setGoogleMap] = useState([]);

  useEffect(() => {
    getLocations();
    getAppConfigData();
  }, []);

  const getLocations = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["vaccineCenters"]).then((result) => {
        if (result.success) {
          setAddress(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const getAppConfigData = async () => {
    try {
      list(appConfig["entityNames"]["masterData"]).then((result) => {
        if (result.success) {
          setGoogleMap(result.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const onFinish = async (values) => {
    console.log(values, "values");
    setLoading(true);
    values["phone"] = USAPhoneNumberFormate(values["phone"]);
    values["address"] = selectedAddress;
    values["coordinate"] = currentLocation;
    let data = {
      entityData: values,
    };
    await add(appConfig["entityNames"]["vaccineCenters"], data)
      .then((result) => {
        if (result.success) {
          document.getElementById("basic").reset();
          setSelectedAddress("");
          setOpen(false);
          getLocations();
          setLoading(false);
          notification["success"]({
            message: "Location created successfully!",
          });
        } else {
          console.log("Error : ", result.error);
          notification["error"]({
            message: "Something went wrong.!",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        notification["error"]({
          message: "Something went wrong.!",
        });
        setLoading(false);
      });
  };

  const handleEdit = (item) => {
    console.log(item, "item");
    setLoading(true);
    if (item && item.id) {
      setRecordId(item.id);
      setIsEditing(true);
      setLoading(false);
      editForm.setFieldsValue({
        clinicName: item.clinicName,
        address: item.address,
        phone: item.phone,
        remarks: item.remarks,
      });
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    values["phone"] = USAPhoneNumberFormate(values["phone"]);
    values["address"] = selectedAddress;
    values["coordinate"] = currentLocation;
    let data = {
      entityData: values,
    };

    update(appConfig["entityNames"]["vaccineCenters"], recordId, data)
      .then((result) => {
        if (result.success) {
          notification["success"]({
            message: `Status changed successfully!`,
          });
          setRecordId("");
          setIsEditing(false);
          getLocations();
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log("Error : ", result.error);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
        setLoading(false);
      });
  };

  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["vaccineCenters"], id).then(
      (deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          getLocations();
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    );
  };

  const handleSelect = (address) => {
    setSelectedAddress(address.label);
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then((res) => {
          console.log(res, "res");
          let coordinate = {
            latitude: res.lat,
            longitude: res.lng,
          };
          setCurrentLocation(coordinate);
        });
      })
      .catch((error) => {
        console.error("Error", error);
        notification["error"]({
          message: "Something went wrong.!",
        });
      });
  };

  const USAPhoneNumberFormate = (phone) => {
    const cleaned = `${phone}`.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      const number = [
        intlCode,
        "(",
        match[2],
        ") ",
        match[3],
        "-",
        match[4],
      ].join("");
      return number;
    }
    return phone;
  };

  return (
    <div>
      <Col
        xxl={{ span: 16, offset: 4 }}
        xl={{ span: 16, offset: 4 }}
        lg={{ span: 16, offset: 4 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Col span={24}>
          <Row justify={"space-between"}>
            <Col>
              <span style={{ fontSize: "20px" }}>Locations</span>
            </Col>
            <Col>
              <Button
                onClick={() => setOpen(true)}
                style={{
                  backgroundColor: "#E28141",
                  color: "#fff",
                  height: "37px",
                }}
                icon={<PlusOutlined />}
              >
                <span> Add</span>
              </Button>
            </Col>
          </Row>
        </Col>

        {isEditing === true ? (
          <Col
            xxl={{ span: 16, offset: 4 }}
            xl={{ span: 16, offset: 4 }}
            lg={{ span: 16, offset: 9 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ marginTop: "20px" }}
          >
            <Card>
              <Form
                onFinish={handleUpdate}
                id="editForm"
                form={editForm}
                style={{ marginTop: "20px" }}
              >
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span>Location Name</span>
                      <Form.Item name="clinicName">
                        <Input style={{ height: "44px" }} />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span>Phone</span>
                      <Form.Item name="phone">
                        <ReactPhoneInput
                          inputExtraProps={{
                            name: "Phone",
                            required: true,
                          }}
                          placeholder="Phone number"
                          country="us"
                          className="phone"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span>Address</span>
                      <Form.Item name="address">
                        <GooglePlacesAutocomplete
                          rules={[
                            {
                              required: true,
                              message: "Please enter Address!",
                            },
                          ]}
                          selectProps={{
                            onChange: (address) => {
                              handleSelect(address);
                            },

                            defaultInputValue: `${
                              userDetails?.address !== undefined &&
                              userDetails?.address !== null
                                ? userDetails?.address
                                : ""
                            }`,
                          }}
                          apiKey={googleMap[0]?.googleMapApi}
                        />
                      </Form.Item>
                      <span>Remarks</span>
                      <Form.Item
                        name="remarks"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Remarks",
                          },
                        ]}
                      >
                        <TextArea
                          // style={{ width: "100%" }}
                          placeholder="Please Enter Remarks"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Row justify={"end"} gutter={[16, 16]}>
                        <Col>
                          <Form.Item htmlType="submit">
                            <Button
                              htmlType="submit"
                              style={{
                                height: "34px",
                                width: "70px",
                                backgroundColor: "white",
                                color: "#E28141",
                                marginTop: "5px",
                                borderColor: "#E28141",
                              }}
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => setIsEditing(false)}
                            style={{
                              height: "34px",
                              width: "70px",
                              backgroundColor: "#d9d9d9",
                              color: "#fff",
                              marginTop: "5px",
                              borderColor: "#d9d9d9",
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Card>
          </Col>
        ) : (
          <Col span={24} style={{ marginTop: "20px" }}>
            {address.map((e) => (
              <Card hoverable style={{ marginBottom: "1rem" }}>
                <Card style={{ padding: "10px", backgroundColor: "#f0f0f0" }}>
                  <Row justify={"space-between"}>
                    <Col
                      xxl={{ span: 20 }}
                      xl={{ span: 20 }}
                      lg={{ span: 20 }}
                      md={{ span: 20 }}
                      sm={{ span: 20 }}
                      xs={{ span: 20 }}
                    >
                      <Col>
                        <b
                          style={{
                            fontSize: "15px",
                            textTransform: "capitalize",
                          }}
                        >
                          {e.clinicName}
                        </b>
                      </Col>
                      <Col>
                        <span
                          style={{
                            color: "#8c8c8c",
                            fontSize: "18px",
                            textTransform: "capitalize",
                          }}
                        >
                          {USAPhoneNumberFormate(e.phone)}
                        </span>
                      </Col>
                      <Col>
                        <span style={{ color: "#8c8c8c" }}>{e.address}</span>
                      </Col>

                      <Col>
                        <span style={{ color: "#8c8c8c" }}>{e.remarks}</span>
                      </Col>
                    </Col>
                    <Col
                      xxl={{ span: 4 }}
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      sm={{ span: 4 }}
                      xs={{ span: 4 }}
                    >
                      <Row justify="center" style={{ marginTop: "5px" }}>
                        <Col>
                          <Button
                            style={{
                              // margin: "1rem",
                              cursor: "pointer",
                              border: "0px",
                              backgroundColor: "#f0f0f0",
                            }}
                            onClick={() => {
                              handleEdit(e);
                              setUserDetails(e);
                            }}
                          >
                            <EditFilled style={{ color: "#003a8c" }} />
                          </Button>
                        </Col>
                        <Col>
                          <Popconfirm
                            title="Delete the Location"
                            description="Are you sure to delete this Location?"
                            onConfirm={() => deleteItem(e.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              style={{
                                border: "0px",
                                backgroundColor: "#f0f0f0",
                              }}
                            >
                              <DeleteFilled style={{ color: "red" }} />
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Card>
            ))}
          </Col>
        )}
      </Col>

      <Modal
        title="Add New Location"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          ref={formRef}
        >
          <Col span={24}>
            <span>Location Name</span>
            <Form.Item
              name="clinicName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Location Name",
                },
              ]}
            >
              <Input
                placeholder="Enter Location Name"
                style={{ height: "44px" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <span>Phone</span>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Phone Number",
                },
              ]}
            >
              <ReactPhoneInput
                inputExtraProps={{
                  name: "Phone",
                  required: true,
                }}
                placeholder="Phone number"
                country="us"
                className="phone"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <span>Address</span>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your Addres",
                },
              ]}
            >
              <GooglePlacesAutocomplete
                selectProps={{
                  onChange: (address) => {
                    handleSelect(address);
                  },
                }}
                apiKey={googleMap[0]?.googleMapApi}
                // autocompletionRequest={{
                //   types: ["(cities)"],
                // }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <span>Remarks</span>
            <Form.Item
              name="remarks"
              rules={[
                {
                  required: true,
                  message: "Please Enter Remarks",
                },
              ]}
            >
              <TextArea rows={4} placeholder="Please Enter Remarks" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify={"end"}>
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#E28141",
                    color: "#fff",
                  }}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Form>
      </Modal>
      <Loading enableLoading={loading} />
    </div>
  );
}
