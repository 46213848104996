import React, { useState } from "react";
import { Col, Tabs } from "antd";
import {
  EnvironmentOutlined,
  ReconciliationOutlined,
  QqOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Location from "./Location";
import PetInfo from "./PetInfo";
import Vaccine from "./Vaccine";
import Config from "./Config";

export default function Settings() {
  const [activeTabKey, setActiveTabKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <div>
      <Col style={{ marginTop: "-20px" }}>
        <Tabs
          animated
          centered
          tabBarGutter={32}
          tabPosition={"top"}
          activeKey={activeTabKey}
          onChange={handleTabChange}
          className="antd-tabs"
          items={[
            {
              label: (
                <span>
                  <EnvironmentOutlined />
                  Locations
                </span>
              ),
              key: "1",
              children: <Location />,
            },
            {
              label: (
                <span>
                  <ReconciliationOutlined />
                  Vaccines
                </span>
              ),
              key: "2",
              children: <Vaccine />,
            },
            {
              label: (
                <span>
                  <QqOutlined />
                  Pet Info
                </span>
              ),
              key: "3",
              children: <PetInfo />,
            },
            {
              label: (
                <span>
                  <SettingOutlined />
                  Config
                </span>
              ),
              key: "4",
              children: <Config />,
            },
          ]}
        />
      </Col>
    </div>
  );
}
