import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Avatar,
  Tabs,
  Table,
  Calendar,
  theme,
  Collapse,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import moment from "moment";
import PetVaccineInfo from "./PetVaccineInfo";

const { Panel } = Collapse;

export default function RequiredAppointments(props) {
  const { token } = theme.useToken();
  const wrapperStyle = {
    padding: "0px 20px",
    width: "100%",
    borderRadius: token.borderRadiusLG,
  };

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showPetInfo, setShowPetInfo] = useState(false);
  const [todayAppointmentsInfo, setTodayAppointmentsInfo] = useState([]);
  const [vaccinesList, setVaccinesList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );

  useEffect(() => {
    getRequiredAppointments(moment(new Date()).format("MM/DD/YYYY"));
    const { requiredAppointment, enablePetInfo } = props;

    if (
      enablePetInfo !== undefined &&
      enablePetInfo !== null &&
      enablePetInfo !== "" &&
      enablePetInfo === true
    ) {
      if (
        requiredAppointment !== undefined &&
        requiredAppointment !== null &&
        requiredAppointment !== ""
      ) {
        setSelectedAppointment(requiredAppointment);
        setShowPetInfo(true);
      } else {
        resetAppointment();
      }
    } else {
      resetAppointment();
    }
  }, [props]);

  const resetAppointment = () => {
    setSelectedAppointment(null);
    setShowPetInfo(false);
  };

  const getRequiredAppointments = (requiredDate) => {
    const { totalAppointments } = props;
    setSelectedDate(requiredDate);
    let todayAppointments = totalAppointments?.filter(
      (event) => moment(event.start).format("MM/DD/YYYY") === requiredDate
    );
    let newList = todayAppointments.sort(function (a, b) {
      return moment(a.start).format("HH") - moment(b.start).format("HH");
    });
    setTodayAppointmentsInfo(newList);
    getVaccinesPlots(newList);
    resetAppointment();
  };

  const getVaccinesPlots = (totalAppointments) => {
    let vaccineData = [];
    totalAppointments.map((item) => {
      item?.vaccines?.map((vaccine) => {
        let result = vaccineData?.some((arrVal) => {
          return arrVal.title === vaccine.vaccineName;
        });
        let reqIndex = vaccineData?.findIndex(
          (item) => item.title === vaccine.vaccineName
        );
        if (result) {
          let record = {
            title: vaccine.vaccineName,
            count: vaccineData[reqIndex].count + 1,
          };
          vaccineData[reqIndex] = record;
        } else {
          let record = {
            title: vaccine.vaccineName,
            count: 1,
          };
          vaccineData.push(record);
        }
      });
    });
    setVaccinesList(vaccineData);
  };

  const filterAppointments = (isVisited) => {
    let appointmentList = isVisited
      ? todayAppointmentsInfo.filter((item) => item?.isVisited == true)
      : todayAppointmentsInfo.filter((item) => item?.isVisited !== true);
    return appointmentList;
  };

  const handleNewAppointment = (event) => {
    setSelectedAppointment(event);
    setShowPetInfo(true);
  };

  const columns = [
    {
      title: "",
      dataIndex: "id",
      render: (id, e) => {
        return (
          <Card
            hoverable
            bodyStyle={{ padding: "5px", margin: "5px" }}
            onClick={() => handleNewAppointment(e)}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row gutter={[16, 16]} justify="center">
                  <Col>
                    <Avatar
                      style={{
                        backgroundColor: "#E28141",
                      }}
                      size={38}
                    >
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {e?.title?.charAt(0).toUpperCase()}
                      </span>
                    </Avatar>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        marginLeft: "5px",
                      }}
                    >
                      {e?.title}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col>
                <div style={{ marginTop: "8px" }}>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <span
                        style={{
                          padding: "4px",
                          fontSize: "12px",
                          color: "#e28141",
                          backgroundColor: "#f5d3bd",
                          borderRadius: "5px",
                        }}
                      >
                        {moment(e?.start).format("hh:mm a")}
                      </span>
                    </Col>
                    <Col>
                      <RightOutlined style={{ color: "#d5d5d5" }} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
        );
      },
    },
  ];

  const showBookings = (isVisited) => {
    return (
      <Table
        className="table"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filterAppointments(isVisited)}
        pagination={{ hideOnSinglePage: true }}
        style={{ overflow: "auto", cursor: "pointer", height: "270px" }}
      />
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <span>Upcoming</span>
        </>
      ),
      children: showBookings(false),
    },
    {
      key: "2",
      label: (
        <>
          <span>Completed</span>
        </>
      ),
      children: showBookings(true),
    },
  ];

  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24} style={wrapperStyle}>
          <Collapse>
            <Panel
              header={
                <span style={{ fontWeight: "bold" }}>Required Vaccines </span>
              }
              key="1"
              extra={
                vaccinesList.length > 0 ? (
                  <div
                    style={{
                      borderRadius: "5px",
                      textAlign: "center",
                      height: "25px",
                      // backgroundColor: "#f5d3bd",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#e28141",
                      }}
                    >
                      {selectedDate === moment(new Date()).format("MM/DD/YYYY")
                        ? "Today"
                        : selectedDate}
                    </span>
                  </div>
                ) : (
                  <div></div>
                )
              }
            >
              <Col span={24}>
                {vaccinesList.length > 0 ? (
                  <div>
                    {vaccinesList.map((vaccine) => (
                      <Row
                        justify={"space-between"}
                        style={{ marginBottom: "5px" }}
                      >
                        <Col>
                          <span>{vaccine?.title}</span>
                        </Col>
                        <Col>
                          <div
                            style={{
                              borderRadius: "5px",
                              textAlign: "center",
                              width: "30px",
                              height: "25px",
                              backgroundColor: "#f5d3bd",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "#e28141",
                              }}
                            >
                              {vaccine?.count}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#b5b5b5",
                      }}
                    >
                      No vaccines found for{" "}
                      {selectedDate === moment(new Date()).format("MM/DD/YYYY")
                        ? "Today"
                        : selectedDate}
                    </span>
                  </div>
                )}
              </Col>
            </Panel>
          </Collapse>
        </Col>

        <div style={wrapperStyle}>
          <Calendar
            fullscreen={false}
            onSelect={(selectedDate) =>
              getRequiredAppointments(selectedDate.format("MM/DD/YYYY"))
            }
          />
        </div>

        <Col span={24}>
          <Card bodyStyle={{ padding: "5px", margin: "5px" }}>
            <Tabs
              className="tabs"
              tabBarGutter={15}
              animated
              tabBarExtraContent={{
                left: (
                  <b style={{ marginRight: "25px", fontSize: "16px" }}>
                    {selectedDate === moment(new Date()).format("MM/DD/YYYY")
                      ? "Today's"
                      : `[ ${selectedDate} ]`}{" "}
                    Visits
                  </b>
                ),
              }}
              defaultActiveKey={1}
              items={items}
            />
          </Card>
        </Col>
      </Row>
      <PetVaccineInfo
        requiredAppointment={selectedAppointment}
        enablePetInfo={showPetInfo}
      />
    </>
  );
}
