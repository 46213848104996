import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Table, notification } from "antd";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";
import dog from "../../../assets/images/dog.png";
import { getDetails } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import { PriceFormate } from "../../../Utilities/Index";
import "./Invoice.css";

export default function Invoice(props) {
  const [userDetails, setUserDetails] = useState([]);
  const [userPets, setUserPets] = useState([]);
  const [userAppointment, setUserAppointment] = useState([]);
  const [petsInfo, setPetsInfo] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const pdfExportComponent = useRef(null);

  useEffect(() => {
    const { petsInfo } = props;
    const { userId, petId, vaccines } = props?.paymentDetails;
    if (userId !== undefined && userId !== null && userId !== "") {
      getUsers(userId, petId);
      setUserAppointment(props?.paymentDetails);
      setPetsInfo(petsInfo);
      if (vaccines.length > 0) {
        let vaccinesAmount = vaccines.reduce(
          (accumulator, currentItem) =>
            accumulator + parseFloat(currentItem.price),
          0
        );
        setTotalAmount(vaccinesAmount);
      }
    }
  }, [props]);

  const getUsers = async (userId, petId) => {
    try {
      const result = await getDetails(
        appConfig["entityNames"]["users"],
        userId
      );
      if (result.success) {
        setUserDetails(result.data);
        let userPetsResult = result.data?.petData;
        if (petId != null && petId !== undefined && petId !== "") {
          let pets = userPetsResult.filter((p) => p.id === petId);
          setUserPets(pets[0]);
        }
      } else {
        notification.error({
          message: "Something went wrong!",
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong!",
      });
    }
  };

  const columns = [
    {
      title: "Vaccine Name",
      dataIndex: "vaccineName",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return (
          <Row justify={"end"}>
            <span>{PriceFormate(price)}</span>
          </Row>
        );
      },
    },
  ];

  const RecordInfo = (label, value) => {
    return (
      <Col style={{ marginTop: "20px" }}>
        <h3
          style={{
            fontSize: "14px",
            color: "#a5a5a5",
            textTransform: "capitalize",
            lineHeight: "10px",
          }}
        >
          {label}
        </h3>
        <h1
          style={{
            fontSize: "16px",
            textTransform: "capitalize",
            lineHeight: "5px",
          }}
        >
          {value}
        </h1>
      </Col>
    );
  };

  const handlePrint = () => {
    setTimeout(() => {
      let printContents = document.getElementById("printablediv").innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      onCancelPrint();
    }, 300);
  };

  const handleDownload = () => {
    pdfExportComponent.current.save();
  };

  const onCancelPrint = () => {
    window.location.replace("/payment");
  };

  return (
    <div>
      <PDFExport
        ref={pdfExportComponent}
        scale={0.8}
        paperSize="A3"
        margin="0.5cm"
        fileName={userDetails?.firstName + "_" + userDetails?.lastName}
      >
        <div
          id="printablediv"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="printinggrp">
            <Row justify={"center"} style={{ padding: "5%" }}>
              <Col span={24}>
                <Row
                  justify={"space-between"}
                  gutter={[32, 32]}
                  style={{ marginTop: "40px" }}
                >
                  <Col span={12}>
                    <Row>
                      <img
                        src={dog}
                        style={{ width: "50px", height: "50px" }}
                        alt="Dog Logo" 
                      />
                      <span
                        style={{
                          fontSize: "22px",
                          fontWeight: "bold",
                          lineHeight: "25px",
                          marginLeft: "10px",
                          letterSpacing: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        VACCINE
                        <br></br>
                        FINDER
                      </span>
                    </Row>
                  </Col>
                  <Col span={12} style={{ textAlign: "end" }}>
                    <Col
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          letterSpacing: "5px",
                        }}
                      >
                        INVOICE
                      </span>
                      <h4 style={{ marginTop: "0px" }}>
                        {moment(userAppointment.start).format(
                          "MM/DD/YY hh:mm a"
                        )}
                      </h4>
                    </Col>
                  </Col>
                </Row>

                <Row
                  justify={"space-between"}
                  gutter={[32, 32]}
                  style={{ marginTop: "40px" }}
                >
                  <Col span={12}>
                    {RecordInfo(
                      "Pet Owner Name",
                      userDetails?.firstName + " " + userDetails?.lastName
                    )}
                    {RecordInfo("Phone #", userDetails?.phone)}
                    {RecordInfo("E-mail", userDetails?.email)}
                  </Col>
                  <Col span={12} style={{ textAlign: "end" }}>
                    {RecordInfo("Pet Name", userPets?.petName)}
                    {RecordInfo("Pet Type", petsInfo[userPets?.petType])}
                    {RecordInfo("Pet Breed", petsInfo[userPets?.breed])}
                  </Col>
                </Row>

                <div style={{ marginTop: "40px" }}>
                  <Table
                    dataSource={props?.paymentDetails?.vaccines}
                    columns={columns}
                    pagination={false}
                  />
                </div>

                <Row justify={"end"}>
                  <div style={{ marginTop: "20px" }}>
                    <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                      Total Amount :{PriceFormate(totalAmount)}
                    </span>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </PDFExport>

      <Col span={24} style={{ padding: "5%" }}>
        <Row gutter={[32, 32]}>
          <Col>
            <Button
              onClick={() => handlePrint()}
              style={{
                color: "red",
                border: `1px solid red`,
              }}
            >
              <PrinterOutlined style={{ color: "red" }} />
              Print
            </Button>
          </Col>

          <Col>
            <Button
              style={{
                color: "#E28141",
                border: `1px solid #E28141`,
              }}
              onClick={() => handleDownload()}
            >
              <DownloadOutlined style={{ color: "#E28141" }} />
              Download
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
