import React, { useState, useEffect } from "react";
import { Col, Row, notification } from "antd";
import moment from "moment";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import appConfig from "../../../config/AppConfig";
import { list } from "../../../api/Apis";
import RequiredAppointments from "../../common/RequiredAppointments";
import Loading from "../../common/Loading";
import "./Calenders.css";

export default function Calenders() {
  const localizer = momentLocalizer(moment);
  const [appointmentsInfo, setAppointmentsInfo] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showPetInfo, setShowPetInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAppointments();
  }, []);

  const getAppointments = async () => {
    setLoading(true);
    setShowPetInfo(false);
    try {
      list(appConfig["entityNames"]["appointments"]).then((result) => {
        if (result.success) {
          setAppointmentsInfo(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const handleAppointment = (event) => {
    setSelectedAppointment(event);
    setShowPetInfo(true);
  };

  return (
    <div>
      <Col span={24}>
        <Row gutter={[32, 32]}>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 10 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <RequiredAppointments
              totalAppointments={appointmentsInfo}
              requiredAppointment={selectedAppointment}
              enablePetInfo={showPetInfo}
            />
          </Col>

          <Col
            xxl={{ span: 18 }}
            xl={{ span: 14 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <BigCalendar
              localizer={localizer}
              events={appointmentsInfo}
              onSelectEvent={(event) => handleAppointment(event)}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "85vh",backgroundColor:"#fff"}}
              views={["month", "agenda"]} //"day",  "work_week",
              defaultView="month"
            />
          </Col>
        </Row>
      </Col>
      <Loading enableLoading={loading} />
    </div>
  );
}
