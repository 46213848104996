import React, { useState, useEffect } from "react";
import { Col, Row, Card, Divider, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import pets2 from "../../../assets/images/pets2.png";
import { Link } from "react-router-dom";
import { Column } from "@ant-design/plots";
import moment from "moment";
import appConfig from "../../../config/AppConfig";
import { list } from "../../../api/Apis";
import RequiredAppointments from "../../common/RequiredAppointments";
import Loading from "../../common/Loading";
export default function Statistic() {
  const [appointmentsInfo, setAppointmentsInfo] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashBoard] = useState([]);
  useEffect(() => {
    getAppointments();
    getDashboard();
  }, []);

  const getAppointments = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["appointments"]).then((result) => {
        if (result.success) {
          setAppointmentsInfo(result.data);
          getAppointmentPlots(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };
  const getDashboard = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["getDashboard"]).then((result) => {
        if (result.success) {
          setDashBoard(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const getAppointmentPlots = (appointmentData) => {
    let plotData = [];
    appointmentData.map((item) => {
      let appointmentDate = moment(item.slotDate).format("MM/DD/YYYY ");
      let scheduleIndex = plotData.findIndex(
        (p) => p?.appointmentDate === appointmentDate
      );
      if (scheduleIndex === -1) {
        let schedule = {
          appointmentDate: appointmentDate,
          appointments: 1,
        };
        plotData.push(schedule);
      } else {
        let schedule = {
          appointmentDate: appointmentDate,
          appointments: plotData[scheduleIndex].appointments + 1,
        };
        plotData[scheduleIndex] = schedule;
      }
    });
    setData(plotData);
  };
  data.sort((a, b) => moment(a.appointmentDate) - moment(b.appointmentDate));
  const config = {
    data,
    xField: "appointmentDate",
    yField: "appointments",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    color: ({ appointmentDate }) => {
      const isSameDate = moment(appointmentDate)
        .startOf("day")
        .isSame(moment(), "day");
      return isSameDate ? "#E28141" : "#f5d3bd";
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      appointmentDate: {
        alias: "Appointment Date",
      },
      appointments: {
        alias: "No. of appointments",
      },
    },
    minColumnWidth: 40,
    maxColumnWidth: 40,
  };

  return (
    <Col span={24}>
      <Row gutter={[32, 32]}>
        <Col
          xxl={{ span: 16 }}
          xl={{ span: 16 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row gutter={[32, 32]}>
            <Col
              xxl={{ span: 12 }}
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Link to="/settings">
                <Card hoverable>
                  <Col span={24}>
                    <Row>
                      <Col span={16}>
                        <span style={{ fontSize: "25px" }}>
                          Vaccine Centers
                        </span>
                      </Col>
                      <Col span={4}>
                        {" "}
                        <Divider
                          type={"vertical"}
                          style={{
                            height: "100%",
                            fontSize: "30px",
                            backgroundColor: "#d9d9d9",
                          }}
                        />
                      </Col>
                      <Col span={4}>
                        <span style={{ fontSize: "25px", color: "#E28141" }}>
                          {dashboard[0]?.vaccineCentersCount}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Link>
            </Col>

            <Col
              xxl={{ span: 12 }}
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Link to="/settings">
                <Card hoverable>
                  <Col span={24}>
                    <Row>
                      <Col span={16}>
                        <span style={{ fontSize: "25px" }}>Vaccines</span>
                      </Col>
                      <Col span={4}>
                        {" "}
                        <Divider
                          type={"vertical"}
                          style={{
                            height: "100%",
                            fontSize: "30px",
                            backgroundColor: "#d9d9d9",
                          }}
                        />
                      </Col>
                      <Col span={4}>
                        <Row justify={"center"}>
                          <span style={{ fontSize: "25px", color: "#E28141" }}>
                            {dashboard[0]?.vaccinesCount}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Link>
            </Col>

            <Col
              xxl={{ span: 12 }}
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Link to="/users">
                <Card hoverable>
                  <Col span={24}>
                    <Row>
                      <Col span={18}>
                        <Col>
                          {" "}
                          <span style={{ fontSize: "25px" }}>Users</span>
                        </Col>
                        <Col>
                          {" "}
                          <span style={{ fontSize: "25px", color: "#E28141" }}>
                            {dashboard[0]?.usersCount}
                          </span>
                        </Col>
                      </Col>
                      <Col span={4}>
                        <span style={{ fontSize: "25px" }}>
                          <UserOutlined
                            style={{ fontSize: "40px", marginTop: "17px" }}
                          />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Link>
            </Col>

            <Col
              xxl={{ span: 12 }}
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Link to="/pets">
                <Card hoverable>
                  <Col span={24}>
                    <Row>
                      <Col span={16}>
                        <Col>
                          {" "}
                          <span style={{ fontSize: "25px" }}>Pets</span>
                        </Col>
                        <Col>
                          <span style={{ fontSize: "25px", color: "#E28141" }}>
                            {dashboard[0]?.petCount}
                          </span>
                        </Col>
                      </Col>
                      <Col span={2}> </Col>
                      <Col span={6}>
                        <span style={{ fontSize: "25px" }}>
                          <img
                            src={pets2}
                            style={{ height: "60px", width: "60px" }}
                            alt="pets2 Logo"
                          />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Link>
            </Col>

            <Col span={24}>
              <Card hoverable>
                <Column {...config} style={{ height: "450px" }} />
              </Card>
            </Col>
          </Row>
        </Col>

        <Col
          xxl={{ span: 8 }}
          xl={{ span: 8 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <RequiredAppointments totalAppointments={appointmentsInfo} />
        </Col>
      </Row>
      <Loading enableLoading={loading} />
    </Col>
  );
}
