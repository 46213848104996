import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import UserDetails from "../application/users/UserDetails";

export default function PetVaccineInfo(props) {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showPetInfo, setShowPetInfo] = useState(false);

  useEffect(() => {
    const { requiredAppointment, enablePetInfo } = props;
    if (
      enablePetInfo !== undefined &&
      enablePetInfo !== null &&
      enablePetInfo !== "" &&
      enablePetInfo === true
    ) {
      if (
        requiredAppointment !== undefined &&
        requiredAppointment !== null &&
        requiredAppointment !== ""
      ) {
        setShowPetInfo(true);
        setSelectedAppointment(requiredAppointment);
      } else {
        resetAppointment();
      }
    } else {
      resetAppointment();
    }
  }, [props]);

  const resetAppointment = () => {
    setSelectedAppointment(null);
    setShowPetInfo(false);
  };

  return (
    <Modal
      title={selectedAppointment?.title + " Details"}
      open={showPetInfo}
      onOk={() => resetAppointment()}
      onCancel={() => resetAppointment()}
      okButtonProps={{
        style: { display: "none" },
      }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
      style={{ minWidth: "90%", padding: "0px" }}
    >
      <div>
        {selectedAppointment?.userId !== undefined &&
        selectedAppointment?.userId !== null &&
        selectedAppointment?.userId !== "" ? (
          <UserDetails
            userId={selectedAppointment?.userId}
            petId={selectedAppointment?.petId}
            appointmentId={selectedAppointment?.id}
          />
        ) : (
          <h4 style={{ textAlign: "center" }}>Please wait..</h4>
        )}
      </div>
    </Modal>
  );
}
