import React, { useState, useEffect } from "react";
import {
  Col,
  Table,
  Row,
  Input,
  notification,
  Button,
  Popconfirm,
  Tag,
  Avatar,
  Image,
  Popover,
} from "antd";
import {
  DeleteFilled,
  SearchOutlined,
  FormOutlined,
  DashOutlined,
} from "@ant-design/icons";
import { list, deleteRecord } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../common/Loading";
import { USAPhoneNumberFormate } from "../../../Utilities/Index";
export default function Users() {
  const [usersData, setUsersData] = useState([]);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["users"]).then((result) => {
        if (result.success) {
          setUsersData(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["users"], id).then(
      (deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          setLoading(false);
          getUsers();
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    );
  };

  const getUserRecords = () => {
    return search.length === 0
      ? usersData
      : usersData.filter(
          (e) =>
            e.firstName?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
            e.lastName?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
            e.email?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
            e.phone?.toLowerCase()?.indexOf(search.toLowerCase()) > -1
        );
  };
  const getImageUrl = (key) => {
    let url = `https://${appConfig["IMAGE_URL"]}.s3.amazonaws.com/public/${key}`;
    return url;
  };

  const columns = [
    {
      title: "Profile",
      dataIndex: "image",
      render: (image, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            <Col>
              {image !== undefined && image !== null && image !== "" ? (
                <Image
                  src={getImageUrl(image)}
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "40px",
                  }}
                />
              ) : (
                <Avatar size={60}>
                  <span style={{ fontSize: "35px" }}>
                    {record?.firstName.charAt(0).toUpperCase()}
                  </span>
                </Avatar>
              )}
            </Col>
          </div>
        );
      },
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (firstName, lastName) => {
        return <div style={{ textTransform: "capitalize" }}>{firstName}</div>;
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (lastName) => {
        return <div style={{ textTransform: "capitalize" }}>{lastName}</div>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (phone) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {USAPhoneNumberFormate(phone)}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      width: "170px",
      title: "Actions",
      dataIndex: "id",
      render: (id, record) => {
        const content = (
          <div>
            {record?.userProfile !== "A" ? (
              <div>
                <p>
                  <Button
                    style={{ border: "0px" }}
                    onClick={() =>
                      (window.location.pathname = `/userDetails/${id}`)
                    }
                  >
                    <FormOutlined />
                    <span>Details</span>
                  </Button>
                </p>

                <p>
                  <Popconfirm
                    title="Delete the User"
                    description="Are you sure to delete this User?"
                    onConfirm={(e) => deleteItem(id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button style={{ border: "0px" }}>
                      <DeleteFilled style={{ color: "red" }} />
                      <span>Delete</span>
                    </Button>
                  </Popconfirm>
                </p>
              </div>
            ) : (
              <Row justify={"center"}>
                <Tag color={"#e28141"}>Admin</Tag>
              </Row>
            )}
          </div>
        );
        return (
          <div>
            <Popover content={content}>
              <Button style={{ border: "0px" }}>
                <DashOutlined />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify={"space-between"}>
        <Col>
          <span style={{ fontSize: "24px" }}>
            Users ({getUserRecords().length})
          </span>
        </Col>
        <Col>
          <Input
            placeholder="Search Users..."
            style={{ height: "60px" }}
            onChange={(e) => setSearch(e.target.value)}
            addonBefore={<SearchOutlined />}
          />
        </Col>
      </Row>

      <Col>
        <Table
          columns={columns}
          dataSource={getUserRecords()}
          pagination={{ hideOnSinglePage: true }}
          style={{ overflow: "auto", cursor: "pointer" }}
          scroll={{ x: 300 }}
        />
      </Col>
      <Loading enableLoading={loading} />
    </div>
  );
}
