import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Row,
  Table,
  Card,
  Collapse,
  notification,
  Avatar,
  Button,
  Image,
  Badge,
  Popconfirm,
  Modal,
  Form,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  BorderOutlined,
  CheckOutlined,
  UserOutlined,
  EditFilled,
} from "@ant-design/icons";
import moment from "moment";
import { list, update } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../common/Loading";
import "./Pets.css";
const { Panel } = Collapse;

export default function Pets(props) {
  const [editForm] = Form.useForm();
  const [open, setOpen] = useState(0);
  const [petsData, setPetsData] = useState([]);
  const [pets, setPets] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editDate, setEditDate] = useState(false);
  useEffect(() => {
    if (props?.userPets?.length > 0) {
      setPetsData(props?.userPets);
    } else {
      getPetsData();
    }
    getPetInfo();
  }, [props]);

  const getPetInfo = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["petInfo"]).then((result) => {
        if (result.success) {
          let records = {};
          result.data.map((petInfo) => {
            records[petInfo.id] = petInfo.title;
          });
          setPets(records);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const getPetsData = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["pets"]).then((result) => {
        if (result.success) {
          setPetsData(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const updatePetInfo = (values) => {
    setLoading(true);
    let id = edit?.id !== "" && edit?.id;
    let data = {
      entityData: values,
    };

    console.log(data, "dataa");
    update(appConfig["entityNames"]["pets"], id, data)
      .then((result) => {
        if (result.success) {
          notification["success"]({
            message: "Record Updated successfully!",
          });
          setIsModalVisible(false);
          setLoading(false);
        } else {
          console.log("Error : ", result.error);
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  const handleVisited = async () => {
    const { userAppointmentId } = props;

    let data = {
      entityData: {
        isNew: false,
        isVisited: true,
      },
    };

    update(appConfig["entityNames"]["appointments"], userAppointmentId, data)
      .then((result) => {
        if (result.success) {
          notification["success"]({
            message: "Appointment completed successfully!",
          });
          // props?.updateAppointment();
          setOpen(false);
        } else {
          console.log("Error : ", result.error);
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  const columnsAppointments = [
    {
      width: "180px",
      title: "Date of Booking",
      dataIndex: "updatedAt",
      render: (updatedAt, record) => {
        return <div>{moment(updatedAt).format("MM/DD/YYYY hh:mm a")}</div>;
      },
    },
    {
      width: "200px",
      title: "Vaccination Date & Time",
      dataIndex: "start",
      render: (start, record) => {
        return (
          <div>
            {moment(record.slotDate).format("MM/DD/YYYY ") + record.slotTime}
          </div>
        );
      },
    },
    {
      title: "Vaccine",
      dataIndex: "vaccines",
      render: (vaccines, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {vaccines?.map((vaccine, index) => (
              <span key={index}>
                {vaccine.vaccineName}
                {index !== vaccines.length - 1 && ", "}
              </span>
            ))}
          </div>
        );
      },
    },

    {
      title: "Location Name",
      dataIndex: "locations",
      render: (locations, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {locations?.clinicName}
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "locations",
      render: (locations, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {locations?.address}
          </div>
        );
      },
    },
  ];

  const historyAppointments = [
    {
      width: "180px",
      title: "Date of Booking",
      dataIndex: "updatedAt",
      render: (updatedAt, record) => {
        return <div>{moment(updatedAt).format("MM/DD/YYYY")}</div>;
      },
    },

    {
      title: "Vaccine",
      dataIndex: "vaccines",
      render: (vaccines, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {vaccines?.map((vaccine, index) => (
              <span key={index}>
                {vaccine.vaccineName}
                {index !== vaccines.length - 1 && ", "}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      width: "200px",
      title: "Booster due date",
      dataIndex: "start",
      render: (start, record) => {
        return (
          <div>
            {moment(record.slotDate).format("MM/DD/YYYY ") + record.slotTime}
          </div>
        );
      },
    },
    {
      // title: "Location Name",
      dataIndex: "locations",
      render: (locations, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {locations?.clinicName}
          </div>
        );
      },
    },
    {
      // title: "Address",
      dataIndex: "locations",
      render: (locations, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {locations?.address}
          </div>
        );
      },
    },
  ];
  const getPetRecords = () => {
    let records =
      search.length === 0
        ? petsData
        : petsData.filter(
            (e) =>
              e?.petName?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
              e?.userName?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
              e?.userName?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
              e?.dateofBirth?.toLowerCase().indexOf(search?.toLowerCase()) > -1
          );
    return records;
  };

  const getAppointmentList = (item) => {
    const { userAppointmentId } = props;
    let appointments =
      window.location.pathname === "/pets" ||
      window.location.pathname.includes("/userDetails")
        ? item?.appointments
        : item?.appointments.filter(
            (appointment) => userAppointmentId === appointment.id
          );
    return appointments;
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const expandedRowRender = (item) => {
    let appoinmentsList = getAppointmentList(item);

    appoinmentsList = appoinmentsList.filter(
      (appointment) => !appointment.isVisited
    );

    return appoinmentsList.length > 0 ? (
      <Table
        columns={columnsAppointments}
        dataSource={appoinmentsList}
        pagination={{ hideOnSinglePage: true }}
        style={{ overflow: "auto", cursor: "pointer" }}
        scroll={{ x: 1000 }}
        sticky={true}
      />
    ) : (
      <Row justify={"center"}>
        <h3>No Records Found</h3>
      </Row>
    );
  };
  const historyRowRender = (item) => {
    let appoinmentsList = getAppointmentList(item);

    appoinmentsList = appoinmentsList.filter(
      (appointment) => appointment.isVisited === true
    );
    return appoinmentsList.length > 0 ? (
      <Table
        columns={historyAppointments}
        dataSource={appoinmentsList}
        pagination={{ hideOnSinglePage: true }}
        style={{ overflow: "auto", cursor: "pointer" }}
        scroll={{ x: 1000 }}
        sticky={true}
      />
    ) : (
      <Row justify={"center"}>
        <h3>No Records Found</h3>
      </Row>
    );
  };

  const getImageUrl = (key) => {
    let url = `https://${appConfig["IMAGE_URL"]}.s3.amazonaws.com/public/${key}`;
    return url;
  };

  const PetInfo = (pet) => {
    return (
      <>
        <Row>
          <Col span={22}>
            <Row gutter={[32, 32]}>
              <Col>
                {pet?.image !== undefined &&
                pet?.image !== null &&
                pet?.image !== "" ? (
                  <Image
                    src={getImageUrl(pet?.image)}
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "40px",
                    }}
                  />
                ) : (
                  <Avatar
                    style={
                      {
                        // backgroundColor: "#E28141",
                      }
                    }
                    size={80}
                  >
                    {pet?.petName !== undefined &&
                    pet?.petName !== null &&
                    pet?.petName !== "" ? (
                      <>
                        <span style={{ fontSize: "35px" }}>
                          {pet?.petName?.charAt(0).toUpperCase()}
                        </span>
                      </>
                    ) : (
                      <>
                        <Avatar
                          size={74}
                          icon={
                            <UserOutlined
                              style={{
                                backgroundColor: "#bfbfbf",
                                color: "#fff",
                              }}
                            />
                          }
                          style={{
                            backgroundColor: "#bfbfbf",
                            marginTop: "-10px",
                          }}
                        />
                      </>
                    )}
                  </Avatar>
                )}
              </Col>
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>Pet Name</p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                  }}
                >
                  {pet?.petName}
                </p>
              </Col>
              {window.location.pathname === "/pets" && (
                <Col>
                  <p style={{ color: "#b5b5b5", marginTop: "15px" }}>
                    Owner Name
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "-15px",
                      textTransform: "capitalize",
                    }}
                  >
                    {pet?.userName}
                  </p>
                </Col>
              )}
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>
                  Date Of Birth
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                  }}
                >
                  {moment(pet?.dateofBirth).format("MM/DD/YYYY")}
                </p>
              </Col>
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>Gender</p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                  }}
                >
                  {pet?.gender}
                </p>
              </Col>
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>Species</p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                  }}
                >
                  {pets[pet?.petType]}
                </p>
              </Col>
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>Pet Breed</p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                  }}
                >
                  {pets[pet?.breed]}
                </p>
              </Col>
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>Microchip</p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                  }}
                >
                  {pet?.microChip}
                </p>
              </Col>
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>Color(s)</p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                  }}
                >
                  {pet?.color}
                </p>
              </Col>
              <Col>
                <p style={{ color: "#b5b5b5", marginTop: "15px" }}>
                  Appointment(s)
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "-15px",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {getAppointmentList(pet).length < 1 ? (
                    <Avatar
                      style={{
                        backgroundColor: "#E28141",
                      }}
                      size={20}
                    >
                      <span>0</span>
                    </Avatar>
                  ) : (
                    <div>
                      <Badge
                        color={
                          getAppointmentList(pet).length > 0
                            ? "#E28141"
                            : "#FFF"
                        }
                        count={getAppointmentList(pet).length}
                      />
                    </div>
                  )}
                </p>
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            {window.location.pathname === "/pets" ? (
              <Col>
                {" "}
                <EditFilled
                  style={{ fontSize: "18px", marginTop: "20px" }}
                  onClick={() => {
                    showModal();
                    setEdit(pet);
                  }}
                />
              </Col>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {window.location.pathname === "/pets" && (
        <Row justify={"space-between"}>
          <Col>
            <span style={{ fontSize: "24px" }}>
              Pets ({getPetRecords().length})
            </span>
          </Col>
          <Col>
            <Input
              placeholder="Search Pets..."
              style={{ height: "60px" }}
              onChange={(e) => setSearch(e.target.value)}
              addonBefore={<SearchOutlined />}
            />
          </Col>
        </Row>
      )}

      <Col>
        {getPetRecords().length > 0 ? (
          <>
            {getPetRecords().map((record, index) => (
              <Card
                hoverable
                bodyStyle={{ padding: "5px" }}
                style={{ borderRadius: "10px", margin: "10px 0px" }}
              >
                <Collapse
                  ghost
                  key={index}
                  accordion={"accordion"}
                  onChange={() => setOpen(index)}
                  activeKey={open}
                  expandIconPosition="end"
                >
                  <Panel
                    header={PetInfo(record)}
                    key={index}
                    extra={
                      window.location.pathname === "/calenders" ? (
                        <Row style={{ marginTop: "20px" }}>
                          <Popconfirm
                            title="Visited"
                            description="Are you sure to Visited...?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() =>
                              getAppointmentList(record)[0]?.isVisited
                                ? console.log("already visited")
                                : handleVisited()
                            }
                          >
                            <Button
                              type={
                                getAppointmentList(record)[0]?.isVisited
                                  ? "primary"
                                  : "default"
                              }
                              icon={
                                getAppointmentList(record)[0]?.isVisited ? (
                                  <CheckOutlined />
                                ) : (
                                  <BorderOutlined />
                                )
                              }
                              title={
                                getAppointmentList(record)[0]?.isVisited
                                  ? "Completed"
                                  : "Visited"
                              }
                            >
                              {getAppointmentList(record)[0]?.isVisited
                                ? "Completed"
                                : "Visited"}
                            </Button>
                          </Popconfirm>
                        </Row>
                      ) : null
                    }
                    showArrow={
                      window.location.pathname === "/pets" ||
                      window.location.pathname.includes("/userDetails")
                        ? true
                        : false
                    }
                  >
                    <Card style={{ backgroundColor: "#f0f0f0" }}>
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        Scheduled Appointment
                      </span>
                      {expandedRowRender(record)}
                    </Card>
                    <Card
                      style={{ backgroundColor: "#f0f0f0", marginTop: "10px" }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        Appointment History
                      </span>
                      {historyRowRender(record)}
                    </Card>
                  </Panel>
                </Collapse>
              </Card>
            ))}
          </>
        ) : (
          <Row justify="center">
            <div className="centerItem">
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "50%",
                }}
              >
                Pet(s) Not Found
              </h1>
            </div>
          </Row>
        )}
      </Col>
      <Loading enableLoading={loading} />
      <Modal
        title="Edit Modal"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          onFinish={updatePetInfo}
          initialValues={{ remember: true }}
          id="editForm"
          form={editForm}
        >
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <span>Pet Name</span>
              <Form.Item name="petName" initialValue={edit?.petName}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span>Owner Name</span>
              <Form.Item name="userName" initialValue={edit?.userName}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <span>Date Of Birth</span>
              <Form.Item
                name="dateofBirth"
              >
                {editDate === true ? (
                  <DatePicker format={"MM/DD/YYYY"} style={{ width: "100%" }} />
                ) : (
                  <Input
                    defaultValue={moment(edit?.dateofBirth).format(
                      "MM/DD/YYYY"
                    )}
                    onClick={() => setEditDate(true)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <span>Gender</span>
              <Form.Item name="gender" initialValue={edit?.gender}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <span>Pet Type</span>
              <Form.Item name="petType" initialValue={edit?.petType}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span>Pet Breed</span>
              <Form.Item name="breed" initialValue={edit?.breed}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <span>Microchip</span>
              <Form.Item name="microChip" initialValue={edit?.microChip}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span>Color(s)</span>
              <Form.Item name="color" initialValue={edit?.color}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"end"}>
            <Col span={4}>
              <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
            </Col>
            <Col span={4}>
              <Button
                htmlType="submit"
                style={{ backgroundColor: "#e28141", color: "#fff" }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
