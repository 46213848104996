import { ApiUrl, noAuthApiUrl, Call, ParseGeneralResponse } from "./ApiUtils";

export function list(entityName, query = {}) {
  let url = ApiUrl(entityName);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function getDetails(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function add(entityName, data) {
  const url = ApiUrl(entityName);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function update(entityName, id, data) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function deleteRecord(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "delete",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function noAuthAdd(entityName, data) {
  const url = noAuthApiUrl(entityName);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}
