import Calenders from "../components/application/calenders/Calenders";
import TimeSlot from "../components/application/settings/TimeSlot";
import Users from "../components/application/users/Users";
import Pets from "../components/application/pets/Pets";
import Statistic from "../components/application/statistic/Statistic";
import Settings from "../components/application/settings/Settings";
import UserDetails from "../components/application/users/UserDetails";
import Payment from "../components/application/payments/Payment";
import Invoice from "../components/application/invoices/Invoice";

const AppRoutes = [
  {
    key: "0",
    path: "/dashboard",
    exact: true,
    name: "dashboard",
    component: <Statistic />,
  },
  {
    key: "1",
    path: "/schedules",
    exact: true,
    name: "schedules",
    component: <TimeSlot />,
  },
  {
    key: "2",
    path: "/calenders",
    exact: true,
    name: "calender",
    component: <Calenders />,
  },
  {
    key: "3",
    path: "/users",
    exact: true,
    name: "users",
    component: <Users />,
  },
  { key: "4", path: "/pets", exact: true, name: "pets", component: <Pets /> },
  {
    key: "5",
    path: "/statistic",
    exact: true,
    name: "statistic",
    component: <Statistic />,
  },
  {
    key: "6",
    path: "/settings",
    exact: true,
    name: "settings",
    component: <Settings />,
  },
  {
    key: "7",
    path: "/userDetails/:id",
    exact: true,
    name: "userDetails",
    component: <UserDetails />,
  },
  {
    key: "8",
    path: "/payment",
    exact: true,
    name: "payment",
    component: <Payment />,
  },
  {
    key: "9",
    path: "/invoice",
    exact: true,
    name: "invoice",
    component: <Invoice />,
  },
];
export default AppRoutes;
