import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Popover,
  Avatar,
  Layout,
  notification,
  Badge,
  Image,
  Modal,
} from "antd";
import {
  BellOutlined,
  SettingOutlined,
  LogoutOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import appConfig from "../../config/AppConfig";
import { list, update } from "../../api/Apis";
import logo from "../../assets/images/dog.png";
import PetVaccineInfo from "./PetVaccineInfo";
import "./loading.css";
const { Header } = Layout;

export default function HeaderComponent(props) {
  const [appointmentsInfo, setAppointmentsInfo] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showPetInfo, setShowPetInfo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userDetails")) ?? {};
  useEffect(() => {
    getAppointments();
  }, []);

  const getAppointments = async () => {
    try {
      list(appConfig["entityNames"]["appointments"]).then((result) => {
        if (result.success) {
          let newAppointments = result.data?.filter((e) => e?.isNew);
          setAppointmentsInfo(newAppointments);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };
  const getImageUrl = (key) => {
    let url = `https://${appConfig["IMAGE_URL"]}.s3.amazonaws.com/public/${key}`;
    return url;
  };
  const handleAppointment = async (event) => {
    setSelectedAppointment(event);
    setShowPetInfo(true);

    let data = {
      entityData: {
        isNew: false,
      },
    };

    update(appConfig["entityNames"]["appointments"], event.id, data)
      .then((result) => {
        if (result.success) {
          getAppointments();
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log("Error : ", result.error);
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const content = (
    <div>
      <p>
        <Link to="/settings">
          <SettingOutlined style={{ fontSize: "17px", color: "#000" }} />
          <span style={{ fontSize: "17px", marginLeft: "5px", color: "#000" }}>
            Settings
          </span>
        </Link>
      </p>

      <div style={{ cursor: "pointer" }} onClick={showModal}>
        <LogoutOutlined style={{ color: "red", fontSize: "17px" }} />
        <span style={{ color: "red", fontSize: "17px" }}> Logout</span>
      </div>

      <Modal
        title={
          <>
            <span>
              <ExclamationCircleOutlined
                style={{
                  backgroundColor: "#faad14",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              />
              <span style={{ marginLeft: "3px" }}>Log Out</span>
            </span>
          </>
        }
        open={isModalOpen}
        onOk={(e) => props.onLogout(e)}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure...?</p>
      </Modal>
    </div>
  );

  const notifications = (
    <Col
      style={{
        margin: 0,
        padding: "5px 5px 0px",
        maxHeight: "450px",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
        borderRadius: "5px",
      }}
    >
      {appointmentsInfo < 1 ? (
        <>(0)Notifications</>
      ) : (
        <>
          {appointmentsInfo.map((e, record) => (
            <div style={{ margin: "0px 0px 5px 0px" }}>
              <Card
                hoverable
                bodyStyle={{ padding: "5px", margin: "5px", minWidth: "250px" }}
                onClick={() => handleAppointment(e)}
              >
                <Row justify={"start"}>
                  <Avatar
                    style={{
                      backgroundColor: "#E28141",
                    }}
                    size={40}
                  >
                    <span>
                      {e?.title?.charAt(0).toUpperCase()}
                      {/* <img
                    src={dogPlaceholder}
                    style={{
                      height: "30px",
                      width: "30px",
                      marginTop: "3px",
                      // color: "white",
                    }}
                  /> */}
                    </span>
                  </Avatar>
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        marginLeft: "5px",
                      }}
                    >
                      {e?.title}
                    </span>

                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "-5px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        {moment(e?.slotDate).format("MM/DD/YYYY ") +
                          e?.slotTime}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          ))}
        </>
      )}
    </Col>
  );

  return (
    <div>
      <Header
        style={{
          backgroundColor: "white",
          position: "fixed",
          width: "100%",
          zIndex: 999,
          marginTop: "-10px",
          boxShadow: "5px 10px 8px #d9d9d9",
          marginLeft: "-8px",
        }}
      >
        <Col span={24}>
          <Row justify={"space-between"}>
            <Col>
              <div
                style={{
                  marginLeft: "-40px",
                  marginTop: "10px",
                }}
              >
                <Row>
                  <Col>
                    <img
                      src={logo}
                      style={{
                        height: "40px",
                        width: "40px",
                      }}
                      alt="Logo"
                    />
                  </Col>
                  <Col>
                    <div style={{ marginTop: "-12px", marginLeft: "15px" }}>
                      <span
                        style={{
                          fontSize: "22px",
                        }}
                        className="popover"
                      >
                        Vaccine Finder
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col>
              <Row gutter={[32, 32]} style={{ marginTop: "5px" }}>
                <Col style={{ marginTop: "7px" }}>
                  <Popover content={notifications} trigger="hover">
                    {appointmentsInfo.length < 1 ? (
                      <>
                        {" "}
                        <Badge count={0} showZero>
                          <BellOutlined style={{ fontSize: "30px" }} />
                        </Badge>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Badge
                          count={appointmentsInfo.length}
                          overflowCount={90}
                        >
                          <BellOutlined style={{ fontSize: "30px" }} />
                        </Badge>
                      </>
                    )}
                  </Popover>
                </Col>
                <Col>
                  {/* <img
                      src={user}
                      style={{
                        height: "40px",
                        borderRadius: "20px",
                        marginTop: "5px",
                      }}
                      alt={localStorage.getItem("userName")}
                    /> */}

                  {/* {userDetails?.firstName?.charAt(0).toUpperCase()} */}

                  {userDetails?.image !== undefined &&
                  userDetails?.image !== null &&
                  userDetails?.image !== "" ? (
                    <>
                      {" "}
                      <Image
                        src={getImageUrl(userDetails?.image)}
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "30px",
                          marginTop: "-3px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Avatar
                        style={{
                          backgroundColor: "#fff",
                          marginTop: "-5px",
                          borderColor: "#E28141",
                        }}
                        size={40}
                      >
                        <span
                          style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "#E28141",
                          }}
                        >
                          {userDetails?.firstName?.charAt(0).toUpperCase()}
                        </span>
                      </Avatar>
                    </>
                  )}

                  <Popover content={content} trigger="hover">
                    <span
                      style={{ marginTop: "-15px", marginLeft: "5px" }}
                      className="popover"
                    >
                      {" "}
                      {userDetails?.firstName +
                        " " +
                        userDetails?.lastName}{" "}
                    </span>
                    <span style={{ marginLeft: "10px" }}>
                      <DownOutlined />
                    </span>
                  </Popover>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Header>

      <PetVaccineInfo
        requiredAppointment={selectedAppointment}
        enablePetInfo={showPetInfo}
      />
    </div>
  );
}
