import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Input,
  Form,
  notification,
  Table,
  Popconfirm,
  Popover,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  DashOutlined,
} from "@ant-design/icons";
import { add, list, deleteRecord, update } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../common/Loading";
export default function Vaccine() {
  const [editForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["vaccine"]).then((result) => {
        if (result.success) {
          setAddress(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    let data = {
      entityData: values,
    };
    await add(appConfig["entityNames"]["vaccine"], data)
      .then((result) => {
        if (result.success) {
          document.getElementById("basic").reset();
          setOpen(false);
          getLocations();
          setLoading(false);
          notification["success"]({
            message: "Vaccine created successfully!",
          });
        } else {
          console.log("Error : ", result.error);
          // setLoading(false);
          notification["error"]({
            message: "Something went wrong.!",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        // setLoading(false);
        notification["error"]({
          message: "Something went wrong.!",
        });
        setLoading(false);
      });
  };

  const handleEdit = (item) => {
    setLoading(true);
    if (item && item.id) {
      setRecordId(item.id);
      setIsEditing(true);
      setLoading(false);
      editForm.setFieldsValue({
        vaccineName: item.vaccineName,
        price: item.price,
      });
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    let data = {
      entityData: values,
    };
    update(appConfig["entityNames"]["vaccine"], recordId, data)
      .then((result) => {
        if (result.success) {
          setLoading(false);
          notification["success"]({
            message: `Status changed successfully!`,
          });
          setRecordId("");
          setIsEditing(false);
          getLocations();
        } else {
          setLoading(false);
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log("Error : ", result.error);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["vaccine"], id).then(
      (deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          setLoading(false);
          getLocations();
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "vaccineName",
      render: (vaccineName, record) => {
        return <div style={{ textTransform: "capitalize" }}>{vaccineName}</div>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {"$"}
            {price}
          </div>
        );
      },
    },

    {
      width: "140px",
      title: "Actions",
      dataIndex: "id",
      render: (id, record) => {
        const content = (
          <div>
            <p>
              <Button
                style={{ border: "0px" }}
                onClick={() => handleEdit(record)}
              >
                <EditFilled style={{ color: "#003a8c" }} />
                <span>Edit</span>
              </Button>
            </p>

            <Popconfirm
              title="Delete the Vaccine"
              description="Are you sure to delete this Vaccine?"
              onConfirm={() => deleteItem(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger style={{ border: "0px" }}>
                <DeleteFilled />
                <span>Delete</span>
              </Button>
            </Popconfirm>
          </div>
        );
        return (
          <div>
            <Popover content={content}>
              <Button style={{ backgroundColor: "#fff", border: "0px" }}>
                <DashOutlined />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Col
        xxl={{ span: 16, offset: 4 }}
        xl={{ span: 16, offset: 4 }}
        lg={{ span: 16, offset: 4 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Col span={24} style={{ marginBottom: "20px" }}>
          <Row justify={"space-between"}>
            <Col>
              <span style={{ fontSize: "20px" }}>Vaccines</span>
            </Col>
            <Col>
              <Button
                onClick={() => setOpen(true)}
                style={{
                  backgroundColor: "#E28141",
                  color: "#fff",
                  height: "37px",
                }}
                icon={<PlusOutlined />}
              >
                <span> Add</span>
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          {isEditing === true ? (
            <Form onFinish={handleUpdate} id="editForm" form={editForm}>
              <Col
                xxl={{ span: 10, offset: 7 }}
                xl={{ span: 10, offset: 7 }}
                lg={{ span: 10, offset: 7 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Row gutter={[16, 16]}>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span>Vaccine Name</span>
                    <Form.Item name="vaccineName">
                      <Input style={{ height: "40px" }} />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span>Price</span>
                    <Form.Item name="price">
                      <Input type="number" addonBefore="$" min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]} justify={"end"}>
                      <Col>
                        {" "}
                        <Form.Item type="submit" htmlType="submit">
                          <Button
                            htmlType="submit"
                            style={{
                              backgroundColor: "white",
                              color: "#E28141",
                              height: "34px",
                              borderColor: "#E28141",
                            }}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => setIsEditing(false)}
                          style={{
                            backgroundColor: "#d9d9d9",
                            color: "#fff",
                            height: "34px",
                            borderColor: "#d9d9d9",
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Form>
          ) : (
            <Table
              columns={columns}
              dataSource={address}
              pagination={{ hideOnSinglePage: true }}
              style={{ overflow: "auto", cursor: "pointer" }}
            />
          )}
        </Col>
      </Col>

      <Modal
        title="Add Vaccine"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Col span={24}>
            <span>Vaccine Name</span>
            <Form.Item
              name="vaccineName"
              rules={[
                {
                  required: true,
                  message: "Please Enter vaccineName",
                },
              ]}
            >
              <Input
                placeholder="Enter Vaccine Name"
                style={{ height: "40px" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <span>Price</span>
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please Enter Vaccine Price",
                },
              ]}
            >
              <Input
                placeholder="Enter Price"
                type="number"
                addonBefore="$"
                min={0}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row justify={"end"}>
              <Col>
                <Form.Item>
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "#E28141",
                      color: "#fff",
                      height: "35px",
                    }}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>
      <Loading enableLoading={loading} />
    </div>
  );
}
