const appConfig = {
  app_version: "Version 2.11.22.4",

  API_GATEWAY: process.env.REACT_APP_API_GATEWAY,
  IMAGE_URL: process.env.REACT_APP_S3_BUCKET,

  entityNames: {
    users: "users",
    vaccineCenters: "vaccineCenters",
    vaccine: "vaccine",
    appointments: "appointments",
    petInfo: "petInfo",
    pets: "pets",
    timeSlots:"timeSlots",
    masterData:"masterData",
    getDashboard:"getDashboard",
  },
};
export default appConfig;
