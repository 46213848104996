import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  Modal,
  Table,
  notification,
  Select,
  DatePicker,
  TimePicker,
  Popconfirm,
  Tabs,
  Popover,
  Card,
} from "antd";
import {
  PlusOutlined,
  DeleteFilled,
  FlagOutlined,
  DashOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { list, add, update, deleteRecord } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import UserDetails from "../users/UserDetails";
import Loading from "../../common/Loading";
import "./Settings.css";
const { Option } = Select;

export default function TimeSlot() {
  const formRef = useRef();
  const [editForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState([]);
  const [locationNames, setLocationNames] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [slotDate, setSlotDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showPetInfo, setShowPetInfo] = useState(false);
  const [vaccines, setVaccines] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [loading, setLoading] = useState(false);
  const [opens, setOpens] = useState(false);

  useEffect(() => {
    initialGetTimeSlots();
    getVaccines();
  }, []);

  const initialGetTimeSlots = () => {
    getLocations();
    getTimeSlots();
  };

  const getVaccines = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["vaccine"]).then((result) => {
        if (result.success) {
          setVaccines(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["vaccineCenters"]).then((result) => {
        if (result.success) {
          setLocation(result.data);
          let records = {};
          result.data.map((clinicInfo) => {
            records[clinicInfo.id] = clinicInfo.clinicName;
          });
          setLocationNames(records);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const getTimeSlots = async () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["timeSlots"]).then((result) => {
        if (result.success) {
          setTimeSlots(result.data);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
      setLoading(false);
    }
  };

  const createTimeSlots = (startTime, endTime, intervalTime) => {
    let fromTime = moment(startTime, "hh:mm A");
    let toTime = moment(endTime, "hh:mm A");
    let slots = [];
    while (fromTime <= toTime) {
      let addNewSlot = {
        id: uuidv4(),
        userId: null,
        petId: null,
        value: new moment(fromTime).format("hh:mm A"),
      };
      slots.push(addNewSlot);
      fromTime.add(intervalTime, "minutes");
    }
    return slots;
  };

  const onFinish = async (values) => {
    formRef.current.resetFields();
    let vaccinesRecords = vaccines.filter((e) =>
      values.vaccines?.includes(e.id)
    );
    let vaccinesList = vaccinesRecords.length > 0 ? vaccinesRecords : [];

    if (locationId === undefined || locationId === null || locationId === "") {
      notification["error"]({
        message: "Please select location!",
      });
      return;
    }

    if (vaccinesList.length < 1) {
      notification["error"]({
        message: "Please select vaccine!",
      });
      return;
    }

    if (slotDate === undefined || slotDate === null || slotDate === "") {
      notification["error"]({
        message: "Please select slot date!",
      });
      return;
    }

    if (startTime === undefined || startTime === null || startTime === "") {
      notification["error"]({
        message: "Please select slot start time!",
      });
      return;
    }

    if (endTime === undefined || endTime === null || endTime === "") {
      notification["error"]({
        message: "Please select slot end time!",
      });
      return;
    }

    if (
      values?.timeInterval === undefined ||
      values?.timeInterval === null ||
      values?.timeInterval === ""
    ) {
      notification["error"]({
        message: "Please select interval time!",
      });
      return;
    }

    let slots = createTimeSlots(startTime, endTime, values?.timeInterval);

    if (slots.length > 0) {
      let data = {
        entityData: {
          locationId: locationId,
          slotDate: slotDate,
          startTime: startTime,
          endTime: endTime,
          availableSlots: slots,
          vaccines: vaccinesList,
          timeInterval: values?.timeInterval,
        },
      };

      await add(appConfig["entityNames"]["timeSlots"], data)
        .then((result) => {
          if (result.success) {
            initialGetTimeSlots();
            notification["success"]({
              message: "TimeSlots created successfully!",
            });
            resetValues();
          } else {
            console.log("Error : ", result.error);
            notification["error"]({
              message: "Something went wrong.!",
            });
          }
        })
        .catch((err) => {
          console.log("Error : ", err);
          notification["error"]({
            message: "Something went wrong.!",
          });
        });
    } else {
      notification["error"]({
        message: "Something went wrong.!",
      });
    }
  };

  const completeSchedule = async (id) => {
    setLoading(true);
    let data = {
      entityData: {
        isComplete: true,
      },
    };

    update(appConfig["entityNames"]["timeSlots"], id, data)
      .then((result) => {
        if (result.success) {
          initialGetTimeSlots();
          notification["success"]({
            message: "Schedule closed!",
          });
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log("Error : ", result.error);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
        setLoading(false);
      });
  };
  const handleEdit = (item) => {
    setRecordId(item.id);
    editForm.setFieldsValue({
      locationId: item.locationId,
      vaccines: item.vaccines.map((vaccine) => vaccine.id),
      slotDate: moment(item.slotDate),
      timeInterval: item.timeInterval,
      startTime: moment(item.startTime),
      endTime: moment(item.endTime),
    });
  };

  const handleUpdate = async (values) => {
    let updateId = recordId;
    let vaccinesRecords = vaccines.filter((e) =>
      values.vaccines?.includes(e.id)
    );
    if (startTime === undefined || startTime === null || startTime === "") {
      notification["error"]({
        message: "Please select slot start time!",
      });
      return;
    }

    if (endTime === undefined || endTime === null || endTime === "") {
      notification["error"]({
        message: "Please select slot end time!",
      });
      return;
    }
    let vaccinesList = vaccinesRecords.length > 0 ? vaccinesRecords : [];
    let slots = createTimeSlots(startTime, endTime, values?.timeInterval);

    let data = {
      entityData: {
        locationId: values.locationId,
        slotDate: values.slotDate,
        startTime: values.startTime,
        endTime: values.endTime,
        availableSlots: slots,
        vaccines: vaccinesList,
        timeInterval: values.timeInterval,
      },
    };

    update(appConfig["entityNames"]["timeSlots"], updateId, data)
      .then((result) => {
        if (result.success) {
          initialGetTimeSlots();
          notification["success"]({
            message: "TimeSlots updated successfully!",
          });
          resetValues();
          setOpens(false);
        } else {
          console.log("Error : ", result.error);
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  const handleAppointments = async (values, type) => {
    setLoading(true);
    let updateId = recordId;
    if (type === "slotUpdate") {
      updateId = values.id;
      delete values["createdAt"];
      delete values["updatedAt"];
      delete values["id"];
    }
    let data = {
      entityData: values,
    };
    update(appConfig["entityNames"]["timeSlots"], updateId, data)
      .then((result) => {
        if (result.success) {
          setLoading(false);
          notification["success"]({
            message: `Status changed successfully!`,
          });
          getLocations();
        } else {
          setLoading(false);
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log("Error : ", result.error);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };
  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["timeSlots"], id).then(
      (deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          initialGetTimeSlots();
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    );
  };

  const handleAppointment = async (event) => {
    setSelectedAppointment(event);
    setShowPetInfo(true);
  };

  const resetAppointment = () => {
    setSelectedAppointment();
    setShowPetInfo(false);
    // resetValues();
  };

  const resetValues = () => {
    setOpen(false);
    setLocation([]);
    setLocationNames([]);
    setTimeSlots([]);
    setLocationId("");
    setSlotDate("");
    setStartTime("");
    setEndTime("");
    setSelectedAppointment(null);
    setShowPetInfo(false);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const showModal = () => {
    setOpens(true);
  };

  const columns = [
    {
      title: "Clinic Name",
      dataIndex: "locationId",
      render: (locationId) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {locationNames[locationId]}
          </div>
        );
      },
    },
    {
      title: "Slot Date",
      dataIndex: "slotDate",
      render: (slotDate) => {
        return <div>{moment(slotDate).format("MM/DD/YYYY")}</div>;
      },
    },
    {
      title: "StartTime",
      dataIndex: "startTime",
      render: (startTime) => {
        return <div>{moment(startTime).format("hh:mm A")}</div>;
      },
    },
    {
      title: "EndTime",
      dataIndex: "endTime",
      render: (endTime) => {
        return <div>{moment(endTime).format("hh:mm A")}</div>;
      },
    },
    // {
    //   title: "Vaccines",
    //   dataIndex: "vaccines",
    //   render: (vaccines, record) => {
    //     const content = (
    //       <div>
    //         {vaccines?.map((e) => (
    //           <span>
    //             {e.vaccineName}
    //             <br />
    //           </span>
    //         ))}
    //       </div>
    //     );
    //     return (
    //       <div>
    //         <Popover content={content} title="Vaccines">
    //           <Button type="primary" style={{ borderRadius: "20px" }}>
    //             {vaccines.length}
    //           </Button>
    //         </Popover>
    //       </div>
    //     );
    //   },
    // },
    {
      width: "140px",
      title: "Actions",
      dataIndex: "id",
      render: (id, record, isBooked, isExpired, isComplete) => {
        const content = (
          <div>
            {record?.isBooked === true ? (
              <>
                {" "}
                <p>
                  <Popconfirm
                    title="Close Schedule"
                    description="Are you sure to close this schedule?"
                    onConfirm={() => completeSchedule(id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger style={{ border: "0px" }}>
                      <FlagOutlined style={{ color: "#003a8c" }} />
                      <span style={{ color: "#000" }}>Close schedule</span>
                    </Button>
                  </Popconfirm>
                </p>
              </>
            ) : (
              <>
                {record?.isComplete === true ? (
                  <>
                    <span style={{ color: "#e28141" }}>Schedule Expired</span>
                  </>
                ) : (
                  <>
                    <p>
                      <Popconfirm
                        title="Close Schedule"
                        description="Are you sure to close this schedule?"
                        onConfirm={() => completeSchedule(id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button danger style={{ border: "0px" }}>
                          <FlagOutlined style={{ color: "#003a8c" }} />
                          <span style={{ color: "#000" }}>Close schedule</span>
                        </Button>
                      </Popconfirm>
                    </p>
                    <p onClick={showModal}>
                      <Button
                        style={{ border: "0px" }}
                        onClick={() => handleEdit(record)}
                      >
                        <EditOutlined />
                        <span style={{ color: "#000" }}>Edit</span>
                      </Button>
                    </p>
                    <p>
                      <Popconfirm
                        title="Delete Schedule"
                        description="Are you sure to delete this schedule?"
                        onConfirm={() => deleteItem(id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button danger style={{ border: "0px" }}>
                          <DeleteFilled style={{ color: "red" }} />
                          <span style={{ color: "#000" }}>Delete</span>
                        </Button>
                      </Popconfirm>
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        );
        return (
          <div style={{ textTransform: "capitalize" }}>
            <Popover content={content}>
              <Button
                style={{ backgroundColor: "white", borderColor: "white" }}
              >
                {isExpired === true ? (
                  <></>
                ) : (
                  <>
                    <DashOutlined />
                  </>
                )}
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];
  const dateFormatList = ["MM/DD/YYYY"];
  const expandedRowRender = (item, event) => {
    const handleSlotClick = (slot, item) => {
      if (!slot.isActive) {
        item?.availableSlots.forEach((element) => {
          if (element.id === slot.id) {
            element["isActive"] = false;
          }
        });
        handleAppointments(item, "slotUpdate");
      } else {
        slot.userId === null || slot.userId === undefined || slot.userId === ""
          ? notification.info({
              message: "No appointment found on this time slot",
            })
          : handleAppointment(event);
      }
    };

    return item?.availableSlots?.length > 0 ? (
      <Row gutter={[16, 16]}>
        {item?.availableSlots.map((slot) => (
          <Col key={slot.id}>
            {slot?.userId === null ||
            slot?.userId === undefined ||
            slot?.userId === "" ? (
              <>
                {" "}
                {slot?.isActive === false ? (
                  <>
                    <Button
                      style={{
                        backgroundColor:
                          (slot?.userId === null ||
                            slot?.userId === undefined ||
                            slot?.userId === "") &&
                          slot?.isActive !== false
                            ? "#fff"
                            : slot?.isActive === false
                            ? "#8c8c8c"
                            : "#e28141",
                        color:
                          (slot?.userId === null ||
                            slot?.userId === undefined ||
                            slot?.userId === "") &&
                          slot?.isActive !== false
                            ? "#e28141"
                            : "#fff",
                      }}
                      onClick={() => {
                        slot?.userId === null ||
                        slot?.userId === undefined ||
                        slot?.userId === ""
                          ? notification["info"]({
                              message: "Not available this slot",
                            })
                          : handleAppointment(
                              slot?.userId,
                              slot?.petId,
                              item.id
                            );
                      }}
                    >
                      <span
                        style={{
                          textDecoration:
                            slot?.isActive === false ? "line-through" : "",
                          color: slot?.isActive === false ? "#fff" : "",
                        }}
                      >
                        {slot?.value}
                      </span>
                    </Button>
                  </>
                ) : (
                  <>
                    <Popconfirm
                      title="Close This appointment"
                      description="Are you sure to Close This appointment...?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleSlotClick(slot, item)}
                    >
                      <Button
                        style={{
                          backgroundColor:
                            (slot?.userId === null ||
                              slot?.userId === undefined ||
                              slot?.userId === "") &&
                            slot?.isActive !== false
                              ? "#fff"
                              : slot?.isActive === false
                              ? "#8c8c8c"
                              : "#e28141",
                          color:
                            (slot?.userId === null ||
                              slot?.userId === undefined ||
                              slot?.userId === "") &&
                            slot?.isActive !== false
                              ? "#e28141"
                              : "#fff",
                        }}
                      >
                        <span
                          style={{
                            textDecoration:
                              slot?.isActive === false ? "line-through" : "",
                            color: slot?.isActive === false ? "#000" : "",
                          }}
                        >
                          {slot?.value}
                        </span>
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </>
            ) : (
              <>
                <Button
                  style={{
                    border: "1px solid #e28141",
                    backgroundColor:
                      slot?.userId === null ||
                      slot?.userId === undefined ||
                      slot?.userId === ""
                        ? "#fff"
                        : "#e28141",
                    color:
                      slot?.userId === null ||
                      slot?.userId === undefined ||
                      slot?.userId === ""
                        ? "#e28141"
                        : "#fff",
                  }}
                  onClick={() => handleAppointment(slot)}
                >
                  <span
                    style={{
                      textDecoration:
                        slot?.isActive === false ? "line-through" : "",
                    }}
                  >
                    {slot?.value}
                  </span>
                </Button>
              </>
            )}
          </Col>
        ))}
      </Row>
    ) : (
      <Row justify={"center"}>
        <h3>No Time Slots</h3>
      </Row>
    );
  };

  const showTable = (isComplete) => {
    const currentDate = moment().startOf("day");
    let filteredTimeSlots = [];

    filteredTimeSlots = timeSlots.filter((schedule) => {
      let slotDate = moment(schedule.slotDate).startOf("day");
      let isExpired = currentDate.isAfter(slotDate);

      if (isComplete) {
        return isExpired === isComplete || schedule.isComplete === isComplete;
      } else {
        return (
          isExpired === false &&
          (schedule.isComplete === false || schedule.isComplete === undefined)
        );
      }
    });

    filteredTimeSlots.sort(function (a, b) {
      return (
        moment(a?.slotDate).format("MMDDYYYY") -
        moment(b?.slotDate).format("MMDDYYYY")
      );
    });

    return (
      <Table
        rowKey={(record) => record.id}
        expandedRowRender={(item) => expandedRowRender(item)}
        columns={columns}
        dataSource={filteredTimeSlots}
        pagination={{ hideOnSinglePage: true }}
        style={{ overflow: "auto", cursor: "pointer" }}
        scroll={{ x: 300 }}
      />
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <span>Upcoming Schedules</span>
        </>
      ),
      children: showTable(false),
    },
    {
      key: "2",
      label: (
        <>
          <span>Completed Schedules</span>
        </>
      ),

      children: showTable(true),
    },
  ];

  return (
    <div>
      <Col
        xxl={{ span: 20, offset: 2 }}
        xl={{ span: 16, offset: 4 }}
        lg={{ span: 20, offset: 2 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Col span={24} style={{ marginTop: "20px" }}>
          <Card>
            <Card style={{ padding: "10px", backgroundColor: "#f0f0f0" }}>
              <Tabs
                tabBarExtraContent={
                  <Button
                    onClick={() => setOpen(true)}
                    style={{
                      backgroundColor: "#E28141",
                      color: "#fff",
                      height: "37px",
                    }}
                    icon={<PlusOutlined />}
                  >
                    <span> Add</span>
                  </Button>
                }
                type="card"
                destroyInactiveTabPane={true}
                defaultActiveKey={1}
                size="small"
                items={items}
              />
            </Card>
          </Card>
        </Col>
      </Col>

      <Modal
        title="New Time Slots"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
      >
        <div>
          <Form onFinish={onFinish} ref={formRef} style={{ marginTop: "20px" }}>
            <Col span={24}>
              <span>Location Name</span>
              <Form.Item name="locationId">
                <Select
                  showSearch
                  placeholder="Select a Location Name"
                  optionFilterProp="children"
                  onChange={(e) => setLocationId(e)}
                  onSearch={onSearch}
                  filterOption={(input, clinicName) =>
                    (clinicName?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  className="customer"
                >
                  {location.map((e) => (
                    <Option value={e.id} label={e.clinicName}>
                      {e.clinicName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <span>Vaccines</span>
              <Form.Item name="vaccines">
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Select a Vaccines"
                  optionFilterProp="children"
                >
                  {vaccines?.map((e) => (
                    <Option value={e.id} label={e.vaccineName}>
                      {e.vaccineName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <span>Date</span>
                  <Form.Item name="date">
                    <DatePicker
                      format={dateFormatList}
                      style={{ width: "100%", height: "40px" }}
                      onChange={(value) => setSlotDate(value?.$d)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span>Interval Time</span>
                  <Form.Item name="timeInterval">
                    <Select
                      showSearch
                      placeholder="Select a Time Interval"
                      optionFilterProp="children"
                      className="customer"
                    >
                      <Option value="5">5 minutes</Option>
                      <Option value="10">10 minutes</Option>
                      <Option value="15">15 minutes</Option>
                      <Option value="30">30 minutes</Option>
                      <Option value="45">45 minutes</Option>
                      <Option value="60">60 minutes</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <span>Start Time</span>
                  <Form.Item name="startTime">
                    <TimePicker
                      format="h:mm a"
                      onChange={(value) => {
                        console.log("value.$d", value.$d);
                        setStartTime(value.$d);
                      }}
                      style={{ width: "100%", height: "40px" }}
                      placeholder="Select Start Time"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span>End Time</span>
                  <Form.Item name="endTime">
                    <TimePicker
                      format="h:mm a"
                      onChange={(value) => {
                        console.log("value.$d", value.$d);
                        setEndTime(value.$d);
                      }}
                      style={{ width: "100%", height: "40px" }}
                      placeholder="Select End Time"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify={"end"}>
                <Col span={4}>
                  <Button
                    style={{
                      height: "40px",
                      width: "70px",
                      marginTop: "3px",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={4}>
                  <Form.Item htmlType="submit">
                    <Button
                      htmlType="submit"
                      style={{
                        height: "40px",
                        width: "70px",
                        backgroundColor: "#E28141",
                        color: "#fff",
                        marginTop: "3px",
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Form>
        </div>
      </Modal>

      <Modal
        title={"Appointment Details"}
        visible={showPetInfo}
        onOk={() => resetAppointment()}
        onCancel={() => resetAppointment()}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        style={{ minWidth: "90%", padding: "0px" }}
      >
        <div>
          {selectedAppointment?.userId !== undefined &&
          selectedAppointment?.userId !== null &&
          selectedAppointment?.userId !== "" ? (
            <UserDetails
              userId={selectedAppointment?.userId}
              petId={selectedAppointment?.petId}
              appointmentId={selectedAppointment?.appointmentId}
            />
          ) : (
            <h4 style={{ textAlign: "center" }}>Please wait..</h4>
          )}
        </div>
      </Modal>
      <Modal
        title="Edit Time Slots"
        open={opens}
        onOk={() => {
          editForm.submit();
          setOpens(false);
        }}
        onCancel={() => {
          editForm.resetFields();
          setOpens(false);
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
      >
        <Form
          onFinish={handleUpdate}
          id="editForm"
          form={editForm}
          initialValues={true}
        >
          <Col span={24}>
            <span>Location Name</span>
            <Form.Item name="locationId">
              <Select
                showSearch
                placeholder="Select a Location Name"
                optionFilterProp="children"
                onChange={(e) => setLocationId(e)}
                onSearch={onSearch}
                filterOption={(input, clinicName) =>
                  (clinicName?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {location.map((e) => (
                  <Option value={e.id} label={e.clinicName}>
                    {e.clinicName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <span>Vaccines</span>
            <Form.Item name="vaccines">
              <Select
                mode="multiple"
                showSearch
                placeholder="Select a Vaccines"
                optionFilterProp="children"
              >
                {vaccines?.map((e) => (
                  <Option value={e.id} label={e.vaccineName}>
                    {e.vaccineName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <span>Date</span>
                <Form.Item name="slotDate">
                  <DatePicker
                    format={dateFormatList}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(value) => setSlotDate(value?.$d)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <span>Interval Time</span>
                <Form.Item name="timeInterval">
                  <Select
                    showSearch
                    placeholder="Select a Time Interval"
                    optionFilterProp="children"
                  >
                    <Option value="5">5 minutes</Option>
                    <Option value="10">10 minutes</Option>
                    <Option value="15">15 minutes</Option>
                    <Option value="30">30 minutes</Option>
                    <Option value="45">45 minutes</Option>
                    <Option value="60">60 minutes</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <span>Start Time</span>
                <Form.Item name="startTime">
                  <TimePicker
                    format="h:mm a"
                    onChange={(value) => {
                      console.log("value.$d", value.$d);
                      setStartTime(value.$d);
                    }}
                    style={{ width: "100%", height: "40px" }}
                    placeholder="Select Start Time"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <span>End Time</span>
                <Form.Item name="endTime">
                  <TimePicker
                    format="h:mm a"
                    onChange={(value) => {
                      console.log("value.$d", value.$d);
                      setEndTime(value.$d);
                    }}
                    style={{ width: "100%", height: "40px" }}
                    placeholder="Select End Time"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={"end"}>
              <Form.Item htmlType="submit">
                <Button
                  htmlType="submit"
                  style={{
                    height: "40px",
                    width: "70px",
                    backgroundColor: "#E28141",
                    color: "#fff",
                    marginTop: "3px",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Form>
      </Modal>
      <Loading enableLoading={loading} />
    </div>
  );
}
