import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Input, notification, Card } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { add, list, deleteRecord, update } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../common/Loading";
export default function Config() {
  const [editForm] = Form.useForm();
  const [googleMap, setGoogleMap] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAppConfigData();
  }, []);

  const getAppConfigData = async () => {
    try {
      list(appConfig["entityNames"]["masterData"]).then((result) => {
        if (result.success) {
          setGoogleMap(result.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    let data = {
      entityData: values,
    };
    await add(appConfig["entityNames"]["masterData"], data)
      .then((result) => {
        if (result.success) {
          setLoading(false);
          document.getElementById("basic").reset();
          getAppConfigData();
          notification["success"]({
            message: "Record created successfully!",
          });
        } else {
          console.log("Error : ", result.error);
          setLoading(false);
          notification["error"]({
            message: "Something went wrong.!",
          });
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        setLoading(false);
        notification["error"]({
          message: "Something went wrong.!",
        });
      });
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    let data = {
      entityData: values,
    };

    update(appConfig["entityNames"]["masterData"], recordId, data)
      .then((result) => {
        if (result.success) {
          notification["success"]({
            message: `Status changed successfully!`,
          });
          getAppConfigData();
          setLoading(false);
          setIsModalOpen(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log("Error : ", result.error);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    setRecordId(id);
  };

  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["masterData"], id).then(
      (deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          getAppConfigData();
          setLoading(false);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {googleMap?.length < 1 ? (
        <></>
      ) : (
        <div>
          {isModalOpen === true ? (
            <Col
              xxl={{ span: 7, offset: 8 }}
              xl={{ span: 8, offset: 8 }}
              lg={{ span: 7, offset: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ marginTop: "40px" }}
            >
              <Card>
                <Card style={{ padding: "10px", backgroundColor: "#f0f0f0" }}>
                  <Form
                    onFinish={handleUpdate}
                    id="editForm"
                    form={editForm}
                    style={{
                      maxWidth: 400,
                    }}
                  >
                    <Col span={24}>
                      <span>Google Map Api</span>
                      <Form.Item name="googleMapApi">
                        <Input style={{ height: "40px" }} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span>Stripe Api</span>
                      <Form.Item name="stripeApi">
                        <Input style={{ height: "40px" }} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span>Location Radius</span>
                      <Form.Item name="locationRadius">
                        <Input style={{ height: "40px" }} type="number" />
                      </Form.Item>
                      <span>Attachment Key</span>
                      <Form.Item name="attachmentKey">
                        <Input style={{ height: "40px" }} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Row justify={"end"} gutter={[16, 16]}>
                        <Col>
                          <Form.Item htmlType="submit">
                            <Button
                              htmlType="submit"
                              style={{
                                justifyContent: "end",

                                backgroundColor: "white",
                                color: "#E28141",
                                borderColor: "#E28141",
                              }}
                            >
                              Update
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Button
                            onClick={handleCancel}
                            style={{
                              backgroundColor: "#d9d9d9",
                              color: "#fff",
                              borderColor: "#d9d9d9",
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Form>
                </Card>
              </Card>
            </Col>
          ) : (
            <div>
              <Col
                xxl={{ span: 7, offset: 8 }}
                xl={{ span: 8, offset: 8 }}
                lg={{ span: 7, offset: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ marginTop: "40px" }}
              >
                <Card>
                  <Card style={{ padding: "10px", backgroundColor: "#f0f0f0" }}>
                    {googleMap?.length < 1 ? (
                      <Col
                        xxl={{ span: 7, offset: 8 }}
                        xl={{ span: 8, offset: 8 }}
                        lg={{ span: 7, offset: 8 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginTop: "40px" }}
                      >
                        <Form
                          name="basic"
                          style={{
                            maxWidth: 400,
                          }}
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          autoComplete="off"
                        >
                          <span style={{ fontSize: "18px" }}>
                            Google Map Api
                          </span>
                          <Form.Item
                            name="googleMapApi"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Google Map Api",
                              },
                            ]}
                          >
                            <Input style={{ height: "40px" }} />
                          </Form.Item>
                          <span style={{ fontSize: "18px" }}>Stripe Api</span>
                          <Form.Item name="stripeApi">
                            <Input style={{ height: "40px" }} />
                          </Form.Item>
                          <span>Location Radius</span>
                          <Form.Item name="locationRadius">
                            <Input style={{ height: "40px" }} type="number" />
                          </Form.Item>
                          <span>Attachment Key</span>
                          <Form.Item name="attachmentKey">
                            <Input style={{ height: "40px" }} />
                          </Form.Item>
                          <Col>
                            <Row justify={"end"}>
                              <Form.Item>
                                <Button
                                  htmlType="submit"
                                  style={{
                                    justifyContent: "end",
                                    backgroundColor: "#E28141",
                                    color: "#fff",
                                  }}
                                >
                                  Submit
                                </Button>
                              </Form.Item>
                            </Row>
                          </Col>
                        </Form>
                      </Col>
                    ) : (
                      <Col
                        xxl={{ span: 16, offset: 9 }}
                        xl={{ span: 16, offset: 9 }}
                        lg={{ span: 16, offset: 9 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        // style={{ marginTop: "40px" }}
                      >
                        {}
                        <Col
                          style={{
                            maxWidth: 400,
                          }}
                        >
                          <Row justify={"end"}>
                            <Col onClick={showModal}>
                              <Button
                                style={{
                                  border: "0px",
                                  backgroundColor: "#e28141",
                                  width: "70px",
                                }}
                                onClick={() => handleEdit(googleMap[0]?.id)}
                              >
                                <EditFilled style={{ color: "#fff" }} />
                              </Button>
                            </Col>
                            {/* <Col>
                <Popconfirm
                  title="Delete Record"
                  description="Are you sure to delete this Record?"
                  onConfirm={() => deleteItem(googleMap[0]?.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger style={{ border: "0px", marginLeft: "10px" }}>
                    <DeleteFilled />
                  </Button>
                </Popconfirm>
              </Col> */}
                          </Row>
                        </Col>
                      </Col>
                    )}
                    <Form
                      onFinish={handleUpdate}
                      initialValues={true}
                      id="editForm"
                      form={editForm}
                      style={{
                        maxWidth: 400,
                        marginTop: "15px",
                      }}
                    >
                      <Col span={24}>
                        <span>Google Map Api</span>
                        <Form.Item
                          name="googleMapApi"
                          initialValue={googleMap[0]?.googleMapApi}
                        >
                          <Input style={{ height: "40px" }} disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <span>Stripe Api</span>
                        <Form.Item
                          name="stripeApi"
                          initialValue={googleMap[0]?.stripeApi}
                        >
                          <Input style={{ height: "40px" }} disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <span>Location Radius</span>
                        <Form.Item
                          name="locationRadius"
                          initialValue={googleMap[0]?.locationRadius}
                        >
                          <Input
                            style={{ height: "40px" }}
                            type="number"
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <span>Attachment Key</span>
                        <Form.Item
                          name="attachmentKey"
                          initialValue={googleMap[0]?.attachmentKey}
                        >
                          <Input style={{ height: "40px" }} disabled={true} />
                        </Form.Item>
                      </Col>
                    </Form>
                  </Card>
                </Card>
              </Col>
            </div>
          )}
        </div>
      )}

      <Loading enableLoading={loading} />
    </div>
  );
}
