import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./components/login/Login";
import Application from "./components/application/Application";

export default function App() {
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <div>
      {window.location.pathname === "/" ||
      window.location.pathname === "/login" ? (
        <BrowserRouter>
          <Routes>
            <Route exact={true} path="/" element={<Login />} />
            <Route exact={true} path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <Application />
      )}
    </div>
  );
}
